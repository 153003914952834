import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../../routes/AppRouter";
import FavoritesList from "../components/favorites/FavoritesList";

export default function FavoritesPage() {

    return (
        <Page title={AppRoutes.Favorites.Label}>
            <FavoritesList />
        </Page>
    )
}