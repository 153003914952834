import React from "react";
import "./CmsGroupContainer.css";
import { generateClassName } from "../../../hooks/useAttributes";
import Icon from "../icons/Icon";
import { CmsGroup } from "./CmsOverview";

export interface ICmsGroupContainerData {
    group: CmsGroup, 
    title: string, 
    expandedGroup: CmsGroup, 
    setExpandedGroup: (group: CmsGroup) => void
}

export interface ICmsGroupContainerProps extends ICmsGroupContainerData {
    children: any,
}

export default function CmsGroupContainer({title, children, expandedGroup, group, setExpandedGroup}: ICmsGroupContainerProps) {
    const expanded = group === expandedGroup;

    const expanderContainerClassName = generateClassName("cms-group-expander-container d-flex flex-row align-items-center w-100 justify-content-between", {
        value: expanded,
        onTrue: "cms-group-expander-container-expanded"
    });

    const expanderClassName = generateClassName("cms-group-expander-icon", {
        value: expanded,
        onTrue: "cms-group-expander-icon-expanded"
    });

    const contentClass = generateClassName("d-flex flex-column h-100 cms-group-content", {
        value: expanded,
        onTrue: "cms-group-content-expanded"
    });

    return (
        <div className="d-flex flex-column gap-2 overflow-hidden">
            <div className={expanderContainerClassName} onClick={() => setExpandedGroup(expanded ? CmsGroup.None : group)} >
                <strong>{title}</strong>
                <Icon className={expanderClassName} icon="chevron-left" />
            </div>
            <div className={contentClass}>
                {
                    children
                }
            </div>
        </div>
    )
}