import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { Status } from "../../../types/ReduxTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import PageLoader from "../loader/PageLoader";
import ToolsItem from "./ProductItem";
import { IDownloadableProduct, WooProductCategory } from "../../../types/WooTypes";
import productsSlice, { clearProducts, getProductsForCategory } from "../../../state/slices/productsSlice";

export default function ProductsList({category}: {category: WooProductCategory}) {

    const [sortedProducts, setSortedProducts] = React.useState<Array<IDownloadableProduct>>([]);

    const products = useAppSelector(state => state.products);
    const dispatch = useAppDispatch();
    
    const productSlice = products[category];
    
    React.useEffect(() => {
        if (!productSlice || productSlice.status === Status.Error || productSlice.status === Status.Idle) dispatch(getProductsForCategory({category: category}));
    }, [products, productSlice]);

    React.useEffect(() => {
        if (!products || !productSlice || !productSlice.products || !productSlice.products.length) {
            setSortedProducts([]);
            return;
        }

        const sortableProducts = [...productSlice.products];

        if (category === WooProductCategory.Tools) {
             setSortedProducts(sortableProducts);
             return;
        }

        const result = sortableProducts.sort((a, b) => {
            if (!a.product) return 1;
            if (!b.product) return -1;
            return b.product.price - a.product.price;
        })

        setSortedProducts(result);

    }, [products, productSlice]);

    if (!productSlice || productSlice.status === Status.Loading) return <PageLoader />
    
    return (
        <div className="d-flex flex-column gap-2 h-100" style={{overflowY: "auto"}} >
            {
                sortedProducts && sortedProducts.length
                ? sortedProducts.map(d => <ToolsItem key={d.id} download={d} />)
                : (
                    <Card>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <span>No Downloads available</span>
                            <Button size="small" text="Refresh" onClick={() => dispatch(getProductsForCategory({category: category}))} />
                        </div>
                    </Card>
                )
            }
        </div>
    )
}