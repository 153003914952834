import React from "react";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import ShowTutorialButton from "./ShowTutorialButton";
import { IDownloadableProduct } from "../../../types/WooTypes";
import DownloadToolButton from "./DownloadProductButton";
import "./ProductItem.css";
import * as purify from "dompurify";
import ExpandableTextBox from "../text/ExpandableTextBox";
import GoToStoreButton from "./GoToStoreButton";
import Icon from "../icons/Icon";

export default function ProductItem({download}: {download: IDownloadableProduct}) {
    if (!download) return null;

    return (
        <Card className="position-relative" style={!download.can_download && {backgroundColor: "var(--backgroundDarker)"}}>
            <div className="d-flex flex-column gap-2">
                <h6 className="fw-bold">{download.product ? download.product.name : "Product"}</h6>
                <div className="d-flex flex-column gap-1">
                    <strong>Description</strong>
                    <ExpandableTextBox defaultValue="No description provided." value={download.product.description} />
                </div>
                {
                    download.can_download && download.tutorials && !!download.tutorials.length && (
                        <div className="d-flex flex-column gap-1">
                            <strong>Tutorials</strong>
                            {
                                download.tutorials.map((d, index) => <ShowTutorialButton key={`show-tutorial-btn-${index}`} link={d} index={index} />)
                            }
                        </div>
                    )
                }
                {
                    download.can_download 
                    ? (
                        <div className="d-flex flex-column w-100 gap-1">
                            <div className="d-flex flex-column align-items-center gap-1">
                                {
                                    download.downloads && download.downloads.length 
                                    ? download.downloads.map(d => <DownloadToolButton download={d} product={download} key={d.id} />)
                                    : <span>No download available</span>
                                }
                            </div>
                        </div>
                    )
                    : (
                        <Card className="d-flex purchase-warning flex-column align-items-center justify-content-center gap-1">
                            <strong><Icon icon="lock" /> Purchase required</strong>
                            <GoToStoreButton product={download} />
                        </Card>
                    )
                }
            </div>
        </Card>
    )
}