import React from "react";
import Page from "../components/container/Page";
import CmsLinkList from "../components/cmsLinks/CmsLinkList";
import { getUsefulLinks } from "../../state/slices/cmsSlice";
import UsefulLink from "../components/knowledge/UsefulLink";
import { useAppSelector } from "../../hooks/reduxHooks";

export default function UsefulLinksPage() {

    const usefulLinks = useAppSelector(state => state.cms.usefulLinks);
    
    return (
        <Page>
            <CmsLinkList
                fetchData={getUsefulLinks}
                multipleItemsTitle="Links"
                renderItem={(l, index) => <UsefulLink key={`${l.url}-${index}`} link={l} />}
                singleItemsTitle="Link"
                slice={usefulLinks}
            />
        </Page>
    )
}