import React from "react";
import Table from "../tables/Table";
import { FieldArray } from "formik";
import { ICmsLinkCategory, ICmsLinkEntry } from "../../../types/WooTypes";
import { generateClassName } from "../../../hooks/useAttributes";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import CmsTableCheckBox from "./CmsTableCheckBox";
import CmsLinkTitleField from "./CmsLinkTitleField";
import LabelButton from "../buttons/LabelButton";
import useRoutes from "../../../hooks/useRoutes";
import ExpandableLinksContainer from "./ExpandableLinksContainer";
import "./CmsTable.css";
import TwoStageButton from "../buttons/TwoStageButton";
import ExpandableComboBox, { IExpandableComboBoxItem } from "../combobox/ExpandableComboBox";
import { IExpandableComboBoxItemProps } from "../combobox/ExpandableComboBoxItem";
import CmsEditableTableCell from "./CmsEditableTableCell";
import Icon from "../icons/Icon";

export default function CmsTable({fieldName, category, data, saveData}: {fieldName: string, category: ICmsLinkCategory, data: Array<ICmsLinkCategory>, saveData: (data: Array<ICmsLinkCategory>) => void}) {

    const [selectedLinks, setSelectedLinks] = React.useState<Array<ICmsLinkEntry>>([]);
    const [filter, setFilter] = React.useState<string>("");

    const {
        openInBrowser
    } = useRoutes();

    const addToSelectedLinks = (e: ICmsLinkEntry) => {
        console.log(e);
        console.log(selectedLinks);
        if (!e) return;

        if (!selectedLinks || !selectedLinks.length) {
            setSelectedLinks([e]);
            return;
        }

        const exists = !!selectedLinks.find(a => a.url === e.url);

        if (exists) return;

        const newLinks = [...selectedLinks, e];
        setSelectedLinks(newLinks);
    }

    const removeFromSelectedLinks = (e: ICmsLinkEntry) => {
        if (!e) return;
        if (!selectedLinks || !selectedLinks.length) return;

        const result = selectedLinks.filter(a => a.url !== e.url);
        setSelectedLinks(result);
    }

    const isChecked = (e: ICmsLinkEntry) => {
        if (!selectedLinks || !selectedLinks.length) return false;
        return !!selectedLinks.find(s => s.url === e.url);
    }

    const getCategories = (): Array<string> => {
        if (!data || !data.length) return [];
        return data.filter(d => d.title !== category.title).map(x => x.title);
    }

    const moveSelectionToCategory = (category: string) => {
        if (!selectedLinks || !selectedLinks.length) return;

        const updatedData = deleteSelectedLinks(false);

        const newData = updatedData.map((d, index): ICmsLinkCategory => ({
            items: d.title === category ? [...d.items, ...selectedLinks.filter(s => !d.items.find(x => x.url === s.url))] : [...d.items],
            title: d.title
        }));

        saveData(newData);
        setSelectedLinks([]);
    }

    const deleteSelectedLinks = (storeData: boolean = true): Array<ICmsLinkCategory> | null => {
        if (!selectedLinks || !selectedLinks.length) return null;

        const newData = [...data.map((d): ICmsLinkCategory => d.title === category.title ? ({
            items: [...d.items.filter(a => !selectedLinks.find(b => a.url === b.url))],
            title: d.title
        }) : {...d})];

        
        if (!storeData) return newData;
        
        saveData(newData);
        setSelectedLinks([]);

        return newData;
    }

    const categories = getCategories();

    return (
        <ExpandableLinksContainer>
            
            <div className="d-flex w-100 flex-row align-items-center justify-content-between gap-5">
                <div className="position-relative">
                    <input className="form-control useful-links-search-bar w-100" type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search..." />
                    <Icon className="position-absolute end-0 top-50 translate-middle" icon={filter ? "x" : "search"} onClick={filter ? () => setFilter("") : undefined} />
                </div>
                {
                    selectedLinks && !!selectedLinks.length && (
                        <div className="d-flex w-100 flex-row align-items-center justify-content-between gap-5">
                            <div className="d-flex flex-column">
                                <strong>Move to Category</strong>
                                {
                                    categories && !!categories.length 
                                    ? (
                                            <div className="d-flex flex-row w-100 flex-wrap gap-3" >
                                                {
                                                    categories.map(c => <LabelButton text={c} onClick={async () => moveSelectionToCategory(c)} />)
                                                }
                                            </div>
                                    )
                                    : <em>No other categories available</em>
                                }
                            </div>
                            <TwoStageButton firstStageIconSize={16} size="small" color="#F00000" firstStageText="Delete Selection" firstStageIcon="trash" text="Sure?" icon="trash" onClick={async () => deleteSelectedLinks()} />                                                                        
                        </div>
                    )
                }
            </div>
            <Table headers={["", "Title", "Description", "URL", "Tags"]}>
                <FieldArray name={`${fieldName}.items`} key={fieldName}>
                    {
                        (arrayHelpers) => {
                            const getChildField = (child: number): string => `${fieldName}.items[${child}]`;

                            const addEmptyChild = () => {
                                const data: ICmsLinkEntry = {
                                    title: "New Link",
                                    url: "https://www.example.com",
                                    description: "New description",
                                    tags: "first, second",
                                }

                                arrayHelpers.insert(0, data);
                            }

                            const checkIfElementMatchesFilter = (b: ICmsLinkEntry) => {
                                if (!filter) return true;

                                const upperVal = filter.toUpperCase();

                                if (!b.title) return false;
                                if (b.title.toUpperCase().includes(upperVal)) return true;
                                if (b.url && b.url.toUpperCase().includes(upperVal)) return true;
                                if (b.description && b.description.toUpperCase().includes(upperVal)) return true;

                                return false;
                            }

                            const content = category && category.items && !!category.items.length
                            ? (
                                category.items.map((b, childIndex) => {

                                    const rowIsIncluded = checkIfElementMatchesFilter(b);

                                    if (!rowIsIncluded) return null;

                                    const rowIsChecked = isChecked(b);

                                    const rowClass = generateClassName({
                                        value: rowIsChecked,
                                        onTrue: "cms-table-group-row-checked"
                                    });

                                    return (
                                        <TableRow className={rowClass} key={getChildField(childIndex)}>
                                            <TableCell>
                                                <div className="w-100 h-100 d-flex align-items-start justify-content-center" style={{paddingTop: "7px"}}>
                                                    <CmsTableCheckBox value={rowIsChecked} setValue={(v) => v ? addToSelectedLinks(b) : removeFromSelectedLinks(b)} />
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <CmsEditableTableCell iconComesFirst bold placeholder="Link title..." name={`${getChildField(childIndex)}.title`} canEdit />
                                            </TableCell>
                                            <TableCell>
                                                <CmsEditableTableCell iconComesFirst placeholder="Describe the link..." as="textarea" name={`${getChildField(childIndex)}.description`} canEdit renderLockedState={() => <span className="w-100">{b.description}</span>} />
                                            </TableCell>
                                            <TableCell>
                                                <CmsEditableTableCell iconComesFirst placeholder="Link url..." name={`${getChildField(childIndex)}.url`} canEdit renderLockedState={() => <LabelButton className="text-start w-100" text={b.url} onClick={async () => openInBrowser(b.url)} />} />
                                            </TableCell>
                                            <TableCell>
                                                <CmsEditableTableCell iconComesFirst placeholder="tag1, tag2, tag3..." name={`${getChildField(childIndex)}.tags`} canEdit />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }).filter(a => !!a)
                            )
                            : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <span>No links yet.</span>
                                    </TableCell>
                                </TableRow>
                            )

                            return (
                                <>
                                    {content}
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <LabelButton icon="plus" onClick={async () => addEmptyChild()} text="Add one" />
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        }
                    }
                </FieldArray>
            </Table>
        </ExpandableLinksContainer>
    )
}