import React from "react";
import { IAppPath } from "../../../routes/AppRouter";
import { generateClassName } from "../../../hooks/useAttributes";
import Icon from "../icons/Icon";
import useRoutes from "../../../hooks/useRoutes";
import { IElementPosition, ISideNavTooltip, SideNavIconSize } from "./SideNav";
import "./SideNavLink.css";

interface ISideNavLinkProps {
    path: IAppPath, 
    otherValidPaths?: Array<IAppPath>, 
    sideBarIsExpanded: boolean,
    visible: boolean,
    setHighlightPosition: (pos: IElementPosition) => void,
    toggleTooltip: (t: ISideNavTooltip | null) => void,
    closeSidebar: () => void
}

export default function SideNavLink({path, otherValidPaths, closeSidebar, toggleTooltip, sideBarIsExpanded, setHighlightPosition, visible}: ISideNavLinkProps) {

    const {
        isActiveRoute,
        currentRoute,
        navigate
    } = useRoutes();

    const [active, setActive] = React.useState<boolean>(false);
    const currentRef = React.useRef<HTMLDivElement>(null);
    
    const setActiveState = (val: boolean) => {
        if (val === active) return;
        setActive(val);
        if (val) moveHighlightToCurrent();
    }

    React.useEffect(() => {
        if (!active) return () => {}
        const x = setTimeout(() => moveHighlightToCurrent(), 200);
        return () => clearTimeout(x);
    }, [sideBarIsExpanded, currentRef, active]);

    React.useEffect(() => {
        if (!path) return setActiveState(false);

        const isActiveLink = isActiveRoute(path.Route);

        if (isActiveLink) return setActiveState(true);

        if (!otherValidPaths || !otherValidPaths.length) return setActiveState(false);

        const otherValidPathsMatch = otherValidPaths.reduce((prev, curr) => {
            if (prev) return true;
            return isActiveRoute(curr.Route);
        }, false);

        setActiveState(otherValidPathsMatch);

    }, [currentRoute]);

    const getPosition = (): IElementPosition => {
        const defaultData: IElementPosition = {height: 0, left: 0, top:0, width: 0};
        if (!currentRef || !currentRef.current) return defaultData;

        const clientRect = currentRef.current.getBoundingClientRect();

        if (!clientRect) return defaultData;

        return clientRect;
    }

    const moveHighlightToCurrent = () => setHighlightPosition(getPosition());
    

    const clickHandler = () => {
        moveHighlightToCurrent();
        navigate(path.Route);
        closeSidebar();
    }

    const containerClass = generateClassName("sidenav-link d-flex flex-row align-items-center", {
        value: active,
        onTrue: "sidenav-link-active"
    }, {
        value: sideBarIsExpanded,
        onTrue: "sidenav-link-sidebar-expanded"
    });

    const toggleTooltipFromData = (show: boolean) => {
        if (sideBarIsExpanded || !show || !path || !path.Label) {
            toggleTooltip(null);
            return;
        }

        toggleTooltip({position: getPosition(), title: path.Label, tooltip: path.Description});
    }

    if (!path) return null;
    if (!visible) return null;
    
    return (
        <div className={containerClass} onClick={clickHandler} ref={currentRef} onMouseEnter={() => toggleTooltipFromData(true)} onMouseLeave={() => toggleTooltipFromData(false)} onMouseOver={() => toggleTooltipFromData(true)}>
            <div className="d-flex flex-row align-items-center justify-content-center" style={{minWidth: "var(--sideNavWidth)"}}>
                <Icon icon={path.Icon} size={SideNavIconSize} />
            </div>
            {
                sideBarIsExpanded && (
                    <div className="d-flex flex-column overflow-hidden">
                        <p className="sidenav-link-label m-0 p-0 fs-6 text-nowrap">{path.Label}</p>
                        {
                            path.Description && <span className="sidenav-link-description text-truncate m-0 p-0">{path.Description}</span>
                        }
                    </div>
                )
            }
        </div>
    )
}