import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import store from "../state/store";
import ModalBanner from "./components/modal/ModalBanner";
import ErrorBoundary from "./ErrorBoundary";

/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "MFA Addin";

const render = (Component: any) => {
  ReactDOM.render(
    <AppContainer>
        <ErrorBoundary>
            <ThemeProvider>
                    <Provider store={store}>
                        <div className="app d-flex flex-column vh-100 vw-100">
                            <ModalBanner />
                            <Component title={title} isOfficeInitialized={isOfficeInitialized} />
                        </div>
                    </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});


if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
