import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { Status } from "../../../types/ReduxTypes";
import { getStoredFavorites, setFavorites } from "../../../state/slices/favoritesSlice";
import Icon from "../icons/Icon";
import { ICmsLinkEntry } from "../../../types/WooTypes";
import { favoritesSetAll } from "../../../api/api";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function ToggleFavoriteButton({link, onHover}: {link: ICmsLinkEntry, onHover?: (isOver: boolean) => void}) {
    const [isFavorite, setIsFavorite] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const {
        sessionToken,
        uniqueIdentifier     
    } = useAppSelector(state => state.session);

    const {
        favorites,
        status
    } = useAppSelector(state => state.favorites);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (status === Status.Idle) dispatch(getStoredFavorites());
    }, []);

    React.useEffect(() => {
        if (!favorites || !favorites.length) {
            setIsFavorite(false);
            return;
        }

        const linkIsFavorite = !!favorites.find(f => f.url === link.url);
        setIsFavorite(linkIsFavorite);
    }, [favorites, status]);

    const getUpdatedFavorites = (): Array<ICmsLinkEntry> => {
        if (!favorites || !favorites.length) return isFavorite ? [] : [link];
        if (isFavorite) return favorites.filter(a => a.url !== link.url);

        const existingIndex = favorites.findIndex(a => a.url === link.url);

        if (existingIndex >= 0) return favorites;

        return [...favorites, link];
    }

    const clickHandler = async () => {

        const newFavorites = getUpdatedFavorites();
        
        setLoading(true);
        await favoritesSetAll({favorites: newFavorites, token: sessionToken, uid: uniqueIdentifier});
        setLoading(false);

        dispatch(setFavorites(newFavorites));
    }

    const btn = (loading || status === Status.Loading) ? <LoadingSpinner size={18} /> : <Icon icon={isFavorite ? "star-fill" : "star"} size={18} onClick={clickHandler} />;

    if (!onHover) return btn;

    return (
        <div className="m-0 p-0" onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)} onMouseOver={() => onHover(true)}>
            {btn}
        </div>
    )
}