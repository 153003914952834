import React from "react"; 
import Card from "../cards/Card";
import Button from "../buttons/Button";
import useRoutes from "../../../hooks/useRoutes";
import ToggleFavoriteButton from "../favorites/ToggleFavoriteButton";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import "./UsefulLink.css";
import { IElementPosition } from "../nav/SideNav";
import { ICmsLinkEntry } from "../../../types/WooTypes";

export default function UsefulLink({link}: {link: ICmsLinkEntry}) {

    const [favoriteBtnHover, setFavoriteBtnHover] = React.useState<boolean>(false);
    const [hoverTimer, setHoverTimer] = React.useState<any>();
    const [hover, setHover] = React.useState<boolean>(false);

    const {
        openInBrowser
    } = useRoutes();

    const clickHandler = () => {
        if (favoriteBtnHover) return;
        openInBrowser(link.url);
    }

    const contentClass = generateClassName("ps-4 pe-4 p-2 position-relative useful-link", {
        value: hover,
        onTrue: "useful-link-hover"
    })

    const toggleHover = (val: boolean) => {
        clearTimeout(hoverTimer);

        if (!val) {
            setHover(false);
            return;
        }

        const x = setTimeout(() => setHover(true), 400);
        setHoverTimer(x);
    }

    return (
        <Card onClick={clickHandler} noPadding >
            <div className={contentClass} onMouseEnter={() => toggleHover(true)} onMouseOver={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <h6 className="text-nowrap m-0 p-0 text-truncate">{link.title}</h6>
                    <ToggleFavoriteButton link={link} onHover={setFavoriteBtnHover}/>
                </div> 
                {
                    hover && (
                        <div className="useful-link-content pt-2">
                            <p>{link.description || "No description provided."}</p>
                        </div>
                    )
                }
            </div>
        </Card>
    )
}