import { Field } from "formik";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import Icon from "../icons/Icon";
import "./CmsEditableTableCell.css";

export default function CmsEditableTableCell({name, placeholder, canEdit, lockedFontSize,  bold, as}: {iconComesFirst?: boolean, as?: string, renderLockedState?: () => any, placeholder: string, name: string, canEdit: boolean, bold?: boolean, lockedFontSize?: string | number}) {
    const inputClass = generateClassName("cms-editable-table-cell-field", {
        base: "fs-",
        value: !!lockedFontSize,
        onTrue: lockedFontSize
    }, {
        value: bold,
        onTrue: "fw-bold"
    })

    const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    const containerClass = generateClassName("d-flex cms-editable-table-cell-container flex-row align-items-center gap-2 w-100");

    return (
        <div className={containerClass} onClick={() => inputRef && inputRef.current && inputRef.current.focus()}>
            <Field name={name} innerRef={inputRef} as={as} placeholder={placeholder} className={inputClass} readOnly={!canEdit} />
        </div>
    )
}