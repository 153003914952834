import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../../routes/AppRouter";
import CmsOverview from "../components/cms/CmsOverview";
import useWindowSize from "../../hooks/useWindowSize";
import CmsTableOverview from "../components/cms/CmsTableOverview";
import { getExcelVersion } from "../../util/versioning";
import { useAppSelector } from "../../hooks/reduxHooks";
import Button from "../components/buttons/Button";
import axios from "axios";

export default function AboutPage() {
    const version = getExcelVersion();

    return (
        <Page title={AppRoutes.About.Label} >
            <div className="d-flex flex-column">
                <strong>Version</strong>
                <span>MFA Add-In v.1.0.3</span>
            </div>
            {
                version && (
                    <div className="d-flex flex-column gap-2">
                        <strong>Additional Information</strong>
                        <div className="d-flex flex-column">
                            <strong style={{color: "var(--primaryAccent)"}}>Host</strong>
                            <span>{version.host}</span>
                        </div>
                        {
                            version.platform && (
                                <div className="d-flex flex-column">
                                    <strong style={{color: "var(--primaryAccent)"}}>Platform</strong>
                                    <span>{version.platform}</span>
                                </div>
                            )
                        }
                        <div className="d-flex flex-column">
                            <strong style={{color: "var(--primaryAccent)"}}>Desktop</strong>
                            <span>{version.isDesktop ? "Yes" : "No"}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <strong style={{color: "var(--primaryAccent)"}}>Version</strong>
                            <span>{version.majorVersion}.{version.minorVersion}.{version.build}.{version.revision}</span>
                        </div>
                        {version.isLocalhost && <em>Local development</em>}
                    </div>
                )
            }
        </Page>
    )
}