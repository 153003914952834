import React from "react";
import { ApiResponse } from "../../../api/api";
import { ICmsLinkCategory, ICmsLinkEntry } from "../../../types/WooTypes";
import { FieldArray, Form, Formik } from "formik";
import CmsGroupContainer, { ICmsGroupContainerData, ICmsGroupContainerProps } from "./CmsGroupContainer";
import { ICmsLinkSlice, getUsefulLinks } from "../../../state/slices/cmsSlice";
import { IUpdateCmsLinksRequest } from "../../../types/ApiRequests";
import { Status } from "../../../types/ReduxTypes";
import PageLoader from "../loader/PageLoader";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import LabelButton from "../buttons/LabelButton";
import FieldWithLabel from "../formik/FieldWithLabel";
import CmsLinkTitleField from "./CmsLinkTitleField";
import TwoStageButton from "../buttons/TwoStageButton";
import useRoutes from "../../../hooks/useRoutes";
import ExpandableLinksContainer from "./ExpandableLinksContainer";
import useApi from "../../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { AsyncThunk } from "@reduxjs/toolkit";

export interface ICmsLinkOverviewProps {
    updateFunction: (data: IUpdateCmsLinksRequest) => Promise<ApiResponse<any>>, 
    fetchData: AsyncThunk<Array<ICmsLinkCategory>, undefined, any>,
    content: ICmsLinkSlice, 
    data: ICmsGroupContainerData
}

export default function CmsLinkOverview({content, updateFunction, data, fetchData}: ICmsLinkOverviewProps) {

    const {
        openInBrowser
    } = useRoutes();

    const {
        sessionToken,
        uniqueIdentifier
    } = useAppSelector(state => state.session);

    const callApi = useApi();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!content || content.status === Status.Idle) dispatch(fetchData());
    }, []);

    if (!content) return null;
    if (content.status === Status.Loading) return <PageLoader />
    if (!content.data) return null;

    return (
        <CmsGroupContainer {...data} >
            <Formik
                initialValues={{
                    data: content.data,
                    token: sessionToken,
                    uid: uniqueIdentifier
                } as IUpdateCmsLinksRequest}
                enableReinitialize
                onSubmit={async (values) => {
                    const res = await callApi(updateFunction(values), true);

                    if (!res || !res.success) return;

                    dispatch(fetchData())
                }}
            >
                {
                    formik => (
                        <Form className="d-flex flex-column h-100">
                            <FieldArray name="data">
                                {
                                    (arrayHelpers) => {

                                        const getFieldIndex = (index: number): string => `data[${index}]`;

                                        const addEmptyItem = () => {
                                            const data: ICmsLinkCategory = {
                                                title: "New Category",
                                                items: [],
                                            }
                                            arrayHelpers.insert(0, data);
                                        }

                                        return (
                                            <div className="d-flex flex-column gap-2 h-100">
                                                <LabelButton icon="plus" text="Add category" onClick={async () => addEmptyItem()} />
                                                <div className="d-flex flex-column h-100 gap-2" style={{overflowY: "auto"}}>
                                                    {
                                                        formik.values.data && !!formik.values.data.length 
                                                        ? formik.values.data.map((a, index) => {
                                                            console.log(formik.values.data);

                                                            if (!a) return null;
                                                            
                                                            return (
                                                                <Card className="d-flex flex-column gap-1" padding={2} key={`${getFieldIndex(index)}`}>
                                                                    <CmsLinkTitleField placeholder="Category title..." bold canEdit name={`${getFieldIndex(index)}.title`} lockedFontSize={6} />
                                                                    <FieldArray name={`${getFieldIndex(index)}.items`} key={getFieldIndex(index)}>
                                                                        {
                                                                            (arrayHelpers) => {
                                                                                const getChildField = (child: number): string => `data[${index}].items[${child}]`;

                                                                                const addEmptyChild = () => {
                                                                                    const data: ICmsLinkEntry = {
                                                                                        title: "New Link",
                                                                                        url: "https://www.example.com",
                                                                                        description: "New description",
                                                                                        tags: "first, second",
                                                                                    }

                                                                                    arrayHelpers.insert(0, data);
                                                                                }

                                                                                return (
                                                                                    <ExpandableLinksContainer isInitiallyExpanded={index === 0}>
                                                                                        <LabelButton icon="plus" onClick={async () => addEmptyChild()} text="Add one" />
                                                                                        {
                                                                                            a.items && !!a.items.length && a.items.map((b, childIndex) => (
                                                                                                <Card padding={2} className="w-100 gap-1" style={{backgroundColor: "var(--backgroundLight)"}}>
                                                                                                    <CmsLinkTitleField bold placeholder="Link title..." name={`${getChildField(childIndex)}.title`} canEdit />
                                                                                                    <CmsLinkTitleField placeholder="Link url..." name={`${getChildField(childIndex)}.url`} canEdit renderLockedState={() => <LabelButton className="text-start w-100" text={b.url} onClick={async () => openInBrowser(b.url)} />} />
                                                                                                    <CmsLinkTitleField placeholder="Describe the link..." as="textarea" name={`${getChildField(childIndex)}.description`} canEdit renderLockedState={() => <span className="w-100">{b.description}</span>} />
                                                                                                    <CmsLinkTitleField placeholder="tag1, tag2, tag3..." name={`${getChildField(childIndex)}.tags`} canEdit />
                                                                                                    <TwoStageButton firstStageIconSize={16} useLabelButton size="small" containerClass="mt-3 w-100" color="#F00000" className="w-100" firstStageText="Delete" firstStageIcon="trash" text="Sure?" icon="trash" onClick={async () => arrayHelpers.remove(childIndex)} />                                                                        
                                                                                                </Card>
                                                                                            ))
                                                                                        }
                                                                                    </ExpandableLinksContainer>
                                                                                )
                                                                            }
                                                                        }
                                                                    </FieldArray>
                                                                    <strong>Actions</strong>
                                                                    <TwoStageButton size="small" color="#F00000" firstStageText="Delete" firstStageIcon="trash" text="Sure?" icon="trash" onClick={async () => arrayHelpers.remove(index)} />                                                                        
                                                                </Card>
                                                            )
                                                        })
                                                        : <span>No categories yet.</span>
                                                    }
                                                </div>
                                                {
                                                    formik.dirty && <Button type="submit" imperativeLoading={formik.isSubmitting} text="Save" icon="save" loadingText="Saving..." />
                                                }
                                            </div>
                                        )
                                    }
                                }
                            </FieldArray>
                        </Form>
                    )
                }
            </Formik>
        </CmsGroupContainer>
    )
}