import React from "react";
import { useAppSelector } from "../hooks/reduxHooks";
import DownloadsPage from "../taskpane/pages/DownloadsPage";
import ProfilePage from "../taskpane/pages/ProfilePage";
import ToolsPage from "../taskpane/pages/ToolsPage";
import UsefulLinksPage from "../taskpane/pages/UsefulLinksPage";
import TrainingVideosPage from "../taskpane/pages/TrainingVideosPage";
import FavoritesPage from "../taskpane/pages/FavoritesPage";
import CmsPage from "../taskpane/pages/CmsPage";
import AboutPage from "../taskpane/pages/AboutPage";

export enum AppRoute {
    Downloads,
    Tools,
    UsefulLinks,
    Training,
    CMS,
    Favorites,
    Profile,
    About
}

export interface IAppPath {
    Route: AppRoute,
    Icon: string,
    Label: string,
    Description?: string
}

const getPath = (route: AppRoute, icon: string, label: string, description?: string): IAppPath => ({
    Icon: icon,
    Label: label,
    Route: route,
    Description: description
})

export const AppRoutes = {
    Downloads: getPath(AppRoute.Downloads, "download", "Underwriting Models", "Access your purchased models"),
    Tools: getPath(AppRoute.Tools, "tools", "Tools", "Download additional underwriting tools"),
    UsefulLinks: getPath(AppRoute.UsefulLinks, "browser-safari", "Helpful Websites", "Collected wisdom at your fingertips"),
    Training: getPath(AppRoute.Training, "mortarboard", "Training", "Find out how things work"),
    CMS: getPath(AppRoute.CMS, "clipboard-data", "CMS", "Manage add-in content"),
    Favorites: getPath(AppRoute.Favorites, "star", "Favorites", "Your favorite links from our collection"),
    Profile: getPath(AppRoute.Profile, "person", "Your Profile"),
    About: getPath(AppRoute.About, "info-circle", "About")
}


export default function AppRouter() {
    const {
        currentRoute
    } = useAppSelector(state => state.navigation);

    const getContentForRoute = () => {
        switch (currentRoute) {
            case AppRoute.Downloads: return <DownloadsPage />;
            case AppRoute.Profile: return <ProfilePage />;
            case AppRoute.Tools: return <ToolsPage />;
            case AppRoute.UsefulLinks: return <UsefulLinksPage />;
            case AppRoute.Training: return <TrainingVideosPage />;
            case AppRoute.Favorites: return <FavoritesPage />;
            case AppRoute.CMS: return <CmsPage />;
            case AppRoute.About: return <AboutPage />;
        }

        return <span>No route matches your request.</span>
    }

    return getContentForRoute();
}