import React, { MouseEventHandler } from "react";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import IElementProps from "../../../types/ElementProps";
import { getTextColorFromBackground } from "../../../util/util";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./Button.css";

export interface IButtonProps extends IElementProps {
    onClick?: () => Promise<any>,
    text?: string, 
    imperativeLoading?: boolean | undefined,
    preventFloatEndOnSubmit?: boolean,
    disabled?: boolean,
    type?: "button" | "submit" | "reset",
    icon?: string, 
    color?: string,
    disabledText?: string,
    iconPosition?: "start" | "end",
    size?: "small" | "regular",
    loadingText?: string
}

export default function Button({className, iconPosition = "start", preventFloatEndOnSubmit = false, onClick, color, disabled, imperativeLoading, text, type, loadingText, children, icon, style, size = "regular"}: IButtonProps) {

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (imperativeLoading === undefined) return;
        setLoading(imperativeLoading);
    }, [imperativeLoading]);

    const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!onClick) return;

        setLoading(true);

        try {
            await onClick();
        }
        catch (err) {
            console.log(err);
        }

        setLoading(false);
    }

    const hasHigherOrderBackgroundColor = style && !!style.backgroundColor;
    
    const convertVarToColor = (color: string): {color: string, isVar: boolean} => {
        if (color) {
            if (!color.includes("#") || color.includes("var(--")) return {
                color: color.replace("var(--", "").replace(")", ""),
                isVar: true
            } 

            return {color: color, isVar: false};
        }
        else return {color: "#000000", isVar: false};
    }

    const getRealBackground = (): {color: string, isVar: boolean} => {
        
        if (color) return convertVarToColor(color);
        else if (style && style.backgroundColor) return convertVarToColor(style.backgroundColor);
        else return {color: "primaryAccent", isVar: true};
    }

    const getRealColor = () => {
        const bg = getRealBackground();
        return getTextColorFromBackground(bg.color, bg.isVar, "#000000");
    }

    const backgroundStyle = generateStyle({
        value: hasHigherOrderBackgroundColor ? style.backgroundColor : (color || "primaryAccent"),
        isVar: !hasHigherOrderBackgroundColor && !color,
        name: "backgroundColor"
    })

    const textStyle = generateStyle({
        value: getRealColor(),
        name: "color"
    }, {
        unit: "em",
        standard: "1",
        value: size === "small" ? "0.8" : undefined,
        name: "fontSize"
    })

    const isSubmit = type === "submit";
    const canFloatEnd = isSubmit && !preventFloatEndOnSubmit;

    const textClass = generateClassName("button-text text-nowrap", {value: loading, onTrue: "ms-2"});
    
    const buttonClass = generateClassName(className, "button position-relative", {
        value: canFloatEnd,
        onTrue: "align-self-end"
    });

    const iconComponent = loading ? <LoadingSpinner size={15} className="me-2" /> : icon && <Icon icon={icon} size={15} />;

    return (
        <button type={type || "button"} onClick={clickHandler} className={buttonClass} style={textStyle} disabled={loading || disabled} >
            <div className="button-background w-100 h-100 position-absolute top-0 start-0" style={backgroundStyle} />
            <div className="button-content position-relative gap-2 d-flex flex-row align-items-center justify-content-center">
                {
                    iconPosition === "start" && iconComponent
                }
                {
                    children || <span className={textClass}>{loading ? (loadingText || "Loading...") : text}</span>
                }
                {
                    iconPosition === "end" && iconComponent
                }
            </div>
        </button>
    )
}