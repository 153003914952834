import React from "react";
import Card from "../cards/Card";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { Status } from "../../../types/ReduxTypes";
import PageLoader from "../loader/PageLoader";
import LogOutButton from "../session/LogOutButton";
import Icon from "../icons/Icon";
import Button from "../buttons/Button";

export default function Profile() {

    const {
        user,
        status
    } = useAppSelector(state => state.session);

    if (status === Status.Loading) return <PageLoader />

    if (!user) return <span>Please log in.</span>

    return (
        <>
            <Card>
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center align-items-start gap-2 gap-sm-0">
                    <div className="d-flex flex-row align-items-center gap-2">
                        <div style={{borderRadius: "var(--borderRadius)", width: "50px", height: "50px", backgroundColor: "#F0F0F0" }} className="d-flex flex-row align-items-center justify-content-center">
                            <Icon icon="person" size={40} />
                        </div>
                        <div className="d-flex flex-column">
                            <h6 className="fw-bold m-0 p-0">{user.name}</h6>
                            <span className="text-truncate">{user.email}</span>
                        </div>
                    </div>
                    <Button onClick={async () => window.open(user.url, "_blank")} text="Show Profile" className="align-self-end align-self-sm-center" />
                </div>
            </Card>
            <div className="d-flex flex-row align-items-center justify-content-end">
                <LogOutButton />
            </div>
        </>
    )
}