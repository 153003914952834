import React, { PropsWithChildren } from "react";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import { IButtonProps } from "./Button";
import "./LabelButton.css";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";

export default function LabelButton({loading, text, className, disabled, color, disabledText, type = "button", loadingText, onClick, children, icon}: IButtonProps) {

    const textClass = generateClassName({value: loading, onTrue: "ms-2"});

    const buttonClass = generateClassName(className, "m-0 p-0 label-button position-relative");

    const style = generateStyle({
        name: "color",
        value: color,
        standard: "var(--primaryAccent)"
    })
    return (
        <button onClick={onClick} type={type} className={buttonClass} disabled={loading || disabled} style={style} >
            <div className="label-button-content position-relative d-flex flex-row align-items-center justify-content-start">
                {
                    loading 
                    ? <LoadingSpinner size={15} />
                    : icon && <Icon size={12} icon={icon} className="me-1"/>
                }
                <span className={textClass}>{loading ? loadingText : (disabled ? disabledText || text : text)}</span>
            </div>
        </button>
    )
}