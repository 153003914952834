import React from "react";
import "./CmsGroupContainer.css";
import { generateClassName } from "../../../hooks/useAttributes";
import Icon from "../icons/Icon";
import { CmsGroup } from "./CmsOverview";

export default function ExpandableLinksContainer({children, isInitiallyExpanded}: {children: any, isInitiallyExpanded?: boolean}) {
    const [expanded, setExpanded] = React.useState<boolean>(isInitiallyExpanded);

    const expanderContainerClassName = generateClassName("cms-group-expander-container d-flex flex-row align-items-center w-100 justify-content-between", {
        value: expanded,
        onTrue: "cms-group-expander-container-expanded"
    });

    const expanderClassName = generateClassName("cms-group-expander-icon", {
        value: expanded,
        onTrue: "cms-group-expander-icon-expanded"
    });

    const contentClass = generateClassName("d-flex flex-column gap-2 cms-group-content", {
        value: expanded,
        onTrue: "cms-group-content-expanded"
    });

    return (
        <div className="d-flex flex-column gap-2">
            <div className={expanderContainerClassName} onClick={() => setExpanded(!expanded)} >
                <strong>Links</strong>
                <Icon className={expanderClassName} icon="chevron-left" />
            </div>
            <div className={contentClass}>
                {
                    children
                }
            </div>
        </div>
    )
}