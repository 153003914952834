import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";
import { AppRoute } from "../../routes/AppRouter";
import { getLocalStorage, setLocalStorage, StorageKey } from "../../util/localStorage";
import { IStatefulSlice, Status } from "../../types/ReduxTypes";
import { ICmsLinkEntry } from "../../types/WooTypes";
import { favoritesGetAll, favoritesSetAll } from "../../api/api";
import store, { RootState } from "../store";

export const getStoredFavorites = createAsyncThunk<Array<ICmsLinkEntry>, undefined, {rejectValue: any}>("favorites/getStoredFavorites", async (arg, {signal, rejectWithValue, getState}) => {
    try {

        const {
            session
        } = getState() as RootState;

        if (!session || !session.sessionToken || !session.uniqueIdentifier) return rejectWithValue(null);

        const storedFavorites = await favoritesGetAll(signal, {token: session.sessionToken, uid: session.uniqueIdentifier});

        if (!storedFavorites) return [];

        return storedFavorites.data;
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

interface IFavoritesSlice extends IStatefulSlice {
    favorites: Array<ICmsLinkEntry>
}

export const FavoritesSlice = createSlice({
    name: "favorites",
    initialState: {
        favorites: [],
        status: Status.Idle
    } as IFavoritesSlice,
    reducers: {
        setFavorites: (state, action: PayloadAction<Array<ICmsLinkEntry>>) => {
            state.favorites = action.payload;
        },
        clearFavorites: (state, action) => {
            state.favorites = [];
            state.status = Status.Idle;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getStoredFavorites.pending, (state, action) => {
            state.status = Status.Loading;
        })
        .addCase(getStoredFavorites.fulfilled, (state, action: PayloadAction<Array<ICmsLinkEntry>>) => {
            state.status = Status.Success;
            state.favorites = action.payload;
        })
        .addCase(getStoredFavorites.rejected, (state, action) => {
            state.status = Status.Error;
            state.error = action.payload || action.error;
        });
    }
});

export const { setFavorites, clearFavorites } = FavoritesSlice.actions;

export default FavoritesSlice.reducer;