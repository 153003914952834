import React from "react";
import IElementProps from "../../../types/ElementProps";
import "./Page.css";
import Logo from "../logo/Logo";
import PageTitle from "./PageTitle";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName } from "../../../hooks/useAttributes";
import useRoutes from "../../../hooks/useRoutes";

export interface IPageProps extends IElementProps {
    title?: string
}

export default function Page({children, title}: IPageProps) {

    const {
        logoVisible     
    } = useAppSelector(state => state.ui);

    const logoContainerClass = generateClassName("w-100 d-flex flex-row align-items-center page-logo justify-content-center", {
        base: "page-logo-",
        value: logoVisible,
        onTrue: "visible",
        standard: "hidden"
    });

    const pageContentClass = generateClassName("d-flex flex-column page-content justify-content-start gap-3 overflow-hidden h-100", {
        value: logoVisible,
        standard: "page-content-no-logo"
    });

    return (
        <>
            <div className={logoContainerClass}>
                <Logo size={150} />
            </div>
            <div className={pageContentClass}>
                {title && <PageTitle title={title} />}
                {children}
            </div>
        </>
    )
}