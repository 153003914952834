import React from "react";
import { ApiResponse } from "../../../api/api";
import { ICmsLinkCategory, ICmsLinkEntry } from "../../../types/WooTypes";
import { FieldArray, Form, Formik } from "formik";
import CmsGroupContainer, { ICmsGroupContainerData, ICmsGroupContainerProps } from "./CmsGroupContainer";
import { ICmsLinkSlice, getUsefulLinks } from "../../../state/slices/cmsSlice";
import { IUpdateCmsLinksRequest } from "../../../types/ApiRequests";
import { Status } from "../../../types/ReduxTypes";
import PageLoader from "../loader/PageLoader";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import LabelButton from "../buttons/LabelButton";
import FieldWithLabel from "../formik/FieldWithLabel";
import CmsLinkTitleField from "./CmsLinkTitleField";
import TwoStageButton from "../buttons/TwoStageButton";
import useRoutes from "../../../hooks/useRoutes";
import ExpandableLinksContainer from "./ExpandableLinksContainer";
import useApi from "../../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import { ICmsLinkOverviewProps } from "./CmsLinkOverview";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import CmsTableCheckBox from "./CmsTableCheckBox";
import { generateClassName } from "../../../hooks/useAttributes";
import CmsTable from "./CmsTable";
import { usefulLinks } from "./x";

export default function CmsTableGroup({content, updateFunction, data, fetchData}: ICmsLinkOverviewProps) {

    const {
        sessionToken,
        uniqueIdentifier
    } = useAppSelector(state => state.session);

    const callApi = useApi();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!content || content.status === Status.Idle) dispatch(fetchData());
    }, []);

    if (!content) return null;
    if (content.status === Status.Loading) return <PageLoader />
    if (!content.data) return null;

    return (
        <CmsGroupContainer {...data} >
            <Formik
                initialValues={{
                    data: content.data,
                    token: sessionToken,
                    uid: uniqueIdentifier
                } as IUpdateCmsLinksRequest}
                enableReinitialize
                onSubmit={async (values) => {
                    const res = await callApi(updateFunction(values), true);

                    if (!res || !res.success) return;

                    dispatch(fetchData())
                }}
            >
                {
                    formik => {
                                           
                        return (

                            <Form className="d-flex flex-column h-100">
                                <FieldArray name="data">
                                    {
                                        (arrayHelpers) => {

                                            const getFieldIndex = (index: number): string => `data[${index}]`;

                                            const addEmptyItem = () => {
                                                const data: ICmsLinkCategory = {
                                                    title: "New Category",
                                                    items: [],
                                                }
                                                arrayHelpers.insert(0, data);
                                            }

                                            return (
                                                <div className="d-flex flex-column gap-2 h-100">
                                                    <LabelButton icon="plus" text="Add category" onClick={async () => addEmptyItem()} />
                                                    <div className="d-flex flex-column h-100 gap-2" style={{overflowY: "auto"}}>
                                                        {
                                                            formik.values.data && !!formik.values.data.length 
                                                            ? formik.values.data.map((a, index) => {
                                                                if (!a) return null;
                                                                
                                                                return (
                                                                    <Card padding="2" className="d-flex flex-column gap-2" key={`${getFieldIndex(index)}`}>
                                                                        <div className="d-flex flex-row align-items-center gap-5 justify-content-between w-100">
                                                                            <CmsLinkTitleField iconComesFirst placeholder="Category title..." bold canEdit name={`${getFieldIndex(index)}.title`} lockedFontSize={6} />
                                                                            <TwoStageButton size="small" color="#F00000" firstStageText="Delete Category" firstStageIcon="trash" text="Sure?" icon="trash" onClick={async () => arrayHelpers.remove(index)} />
                                                                        </div>
                                                                        <CmsTable category={a} data={formik.values.data} fieldName={getFieldIndex(index)} saveData={(val) => formik.setFieldValue("data", val)} />
                                                                    </Card>
                                                                )
                                                            })
                                                            : <span>No categories yet.</span>
                                                        }
                                                    </div>
                                                    {
                                                        formik.dirty && <Button type="submit" imperativeLoading={formik.isSubmitting} text="Save" icon="save" loadingText="Saving..." />
                                                    }
                                                </div>
                                            )
                                        }
                                    }
                                </FieldArray>
                            </Form>
                        )
                    }
                }
            </Formik>
        </CmsGroupContainer>
    )
}