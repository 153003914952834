import React from "react";
import Page from "../components/container/Page";
import Profile from "../components/user/Profile";
import { AppRoutes } from "../../routes/AppRouter";

export default function ProfilePage() {
    return (
        <Page title={AppRoutes.Profile.Label}>
            <Profile />
        </Page>
    )
}