import { Host, getExcelVersion } from "./versioning";

export enum Cookies {
    Session = "mfa-id",
    UniqueId = "mfa-suid"
}

export const getCookie = (cookie: Cookies): any => {
    try {
        const value = `; ${document.cookie}`;
    
        const parts = value.split(`; ${cookie}=`);
        if (!parts || parts.length !== 2) return null;
        return parts.pop()?.split(';').shift();
    }
    catch (err) {
        console.error(err);
        return null;
    }
}

export const deleteCookie = (cookie: Cookies) => {
    try {
        const storedCookie = getCookie(cookie);

        if (!storedCookie) return;
        
        const date = new Date(Date.parse("Thu, 01 Jan 1970 00:00:01 GMT"));
        document.cookie = `${cookie}=; expires=${date.toUTCString()}; path=/`;
    }
    catch (err) {
        console.error(err);
    }
}

export const setCookie = (cookie: string, value: any, days: number = 1) => {
    try {
        if (!value) return;
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        document.cookie = `${cookie}=${value}; expires=${date.toUTCString()}; path=/; SameSite=None; Secure `;
    }
    catch (err) {
        console.error(err);
    }
}