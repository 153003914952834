import * as React from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import LogInPage from "../pages/LogInPage";
import { Status } from "../../types/ReduxTypes";
import PageLoader from "./loader/PageLoader";
import VersionNotSupportedPage from "../pages/VersionNotSupportedPage";
import NotOpenedInsideExcelPage from "../pages/NotOpenedInsideExcelPage";
import SideNav from "./nav/SideNav";
import "./App.css";
import AppRouter from "../../routes/AppRouter";
import { getExcelVersion } from "../../util/versioning";
import { setCookie } from "../../util/cookies";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export default function App({isOfficeInitialized}: AppProps) {

    const {
        sessionToken,
        uniqueIdentifier,
        user,
        status
    } = useAppSelector(state => state.session);

    getExcelVersion();

    if (!isOfficeInitialized) return <PageLoader />

    if (status === Status.Loading) return <PageLoader />
    
    if (!sessionToken || !uniqueIdentifier || !user) return <LogInPage />
    
    return (
        <div className="h-100 w-100 d-flex flex-row">
            <SideNav />
            <div className="app-content d-flex flex-column">
                <AppRouter />
            </div>
        </div>
    );
}
