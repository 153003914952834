import { Field } from "formik";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import Icon from "../icons/Icon";
import "./CmsLinkTitleField.css";

export default function CmsLinkTitleField({name, placeholder, canEdit, lockedFontSize,  bold, as, renderLockedState, iconComesFirst = false}: {iconComesFirst?: boolean, as?: string, renderLockedState?: () => any, placeholder: string, name: string, canEdit: boolean, bold?: boolean, lockedFontSize?: string | number}) {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isHover, setIsHover] = React.useState<boolean>(false);

    const inputClass = generateClassName("cms-link-title-field", {
        base: "cms-link-title-field-",
        value: isEditing,
        onTrue: "editing",
        standard: "locked"
    }, {
        base: "fs-",
        value: !isEditing && lockedFontSize,
        onTrue: lockedFontSize
    }, {
        value: bold && !isEditing,
        onTrue: "fw-bold"
    })

    const containerClass = generateClassName("d-flex cms-link-title-container flex-row align-items-center gap-2 w-100", {
        value: isEditing,
        onTrue: "cms-link-title-container-editing"
    })

    const icon = canEdit && isHover && <Icon icon={isEditing ? "check" : "pen"} onClick={() => setIsEditing(!isEditing)} />;

    return (
        <div className={containerClass} onMouseEnter={() => setIsHover(canEdit)} onMouseLeave={() => setIsHover(canEdit && isEditing)} onMouseOver={() => setIsHover(canEdit)} >
            {
                iconComesFirst && icon
            }
            {
                (!isEditing && renderLockedState !== undefined)
                ? <div className="m-0 p-0" onClick={!isEditing ? () => setIsEditing(true) : undefined}>{renderLockedState()}</div>
                : <Field name={name} as={as} placeholder={placeholder} className={inputClass} readOnly={!canEdit || !isEditing} onClick={!isEditing ? () => setIsEditing(true) : undefined}/>
            }
            {
                !iconComesFirst && icon
            }
        </div>
    )
}