import React from "react";
import Page from "../components/container/Page";
import Card from "../components/cards/Card";
import { AppRoutes } from "../../routes/AppRouter";
import { getTutorials } from "../../state/slices/cmsSlice";
import TutorialItem from "../components/tutorials/TutorialItem";
import { useAppSelector } from "../../hooks/reduxHooks";
import CmsLinkList from "../components/cmsLinks/CmsLinkList";

export default function TrainingVideosPage() {
    const tutorials = useAppSelector(state => state.cms.tutorials);

    return (
        <Page>
            <CmsLinkList
                fetchData={getTutorials}
                multipleItemsTitle="Tutorials"
                renderItem={(l) => <TutorialItem key={l.url} tutorial={l} />}
                singleItemsTitle="Tutorial"
                slice={tutorials}
            />
        </Page>
    )
}