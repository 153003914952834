import React, { MouseEventHandler } from "react";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import IElementProps from "../../../types/ElementProps";
import "./Icon.css";

interface IIconProps extends IElementProps {
    icon: string,
    labelClass?: string,
    label?: string,
    onClick?: Function,
    size?: number
}

export default function Icon({icon, size, onClick, label, style, className, labelClass}: IIconProps) {
    
    const iconStyle = generateStyle({
            name: "fontSize",
            standard: 16,
            unit: "px",
            value: size
        }, {
            name: "lineHeight",
            standard: 16,
            unit: "px",
            value: size
        }
    );

    const iconClass = generateClassName("icon", className, { base: "bi-", value: icon }, { value: onClick, onTrue: "icon-clickable" });

    const clickHandler = onClick ? () => onClick() : undefined;

    if (!label) return <i className={iconClass} onClick={clickHandler} style={style ? {...style, ...iconStyle} : iconStyle}></i>;

    const containerClass = generateClassName("p-0 m-0 gap-1 d-flex align-items-center", { value: onClick, onTrue: "icon-container-clickable" });

    const labelClassName = generateClassName(labelClass, "icon-label text-nowrap");

    return (
        <div className={containerClass} onClick={clickHandler}>
            <i className={iconClass} style={style ? {...style, ...iconStyle} : iconStyle}></i>
            <span className={labelClassName}>{label}</span>
        </div>
    )
}