const hexToRgb = (hex: string): {r: number, b: number, g: number} | null => {
    if (!hex || typeof hex !== "string") return null;
    var result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex.trim());
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export const parseVarColorToHex = (color: string): string => {
  const computedValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}`);

  if (!computedValue) return "#FFFFFF";

  return computedValue;
}

export const getTextColorFromBackground = (color: string, isVar: boolean = false, standard: string = "#000000"): string => {

    if (isVar) color = parseVarColorToHex(color);

    const rgb = hexToRgb(color);

    if (!rgb) return standard;
    if (!color) return standard;

    if ((rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114) > 186) return "#000000";
    
    return "#FFFFFF";
}

export enum Asset {
    Logo = "logo",
    Arrow = "arrow",
    ValueAnalysis = "value_analysis",
    ValueStart = "value_start",
    ValueEnd = "value_end",
    ValueValuation = "value_valuation",
    ValueTools = "value_tools",
    ValueLinks = "value_links",
    ValueTime = "value_time",
}

export const getAsset = (asset: Asset): any => require(`../assets/${asset}.png`)


export const formatNumber = (n: any): string => {
    try {
        let number = n;
        if (typeof n !== "number") number = parseFloat(n);
        return new Intl.NumberFormat("de-DE").format(number);
    }
    catch { }

    return n;
}

export const getObjectFromInterface = <T = any>(): Object => ({} as T);