import React from "react";
import "./SideNav.css";
import Icon from "../icons/Icon";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import SideNavLink from "./SideNavLink";
import { AppRoutes, IAppPath } from "../../../routes/AppRouter";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/reduxHooks";

export interface IElementPosition {
    left: number,
    top: number,
    width: number,
    height: number
}

export interface ISideNavTooltip {
    position: IElementPosition,
    title: string,
    tooltip: string
}

export const SideNavIconSize = 24;

export default function SideNav() {
    
    const [expanded, setExpanded] = React.useState<boolean>(false);
    
    const [currentHighlightPosition, setCurrentHighlightPosition] = React.useState<IElementPosition>({left: 0, top: 0, width: 0, height: 0});
    const [usedHighlightPosition, setUsedHighlightPosition] = React.useState<IElementPosition>({left: 0, top: 0, width: 0, height: 0})
    
    const [tooltip, setTooltip] = React.useState<ISideNavTooltip>({position: {left: 0, top: 0, width: 0, height: 0}, title: "", tooltip: ""});
    
    const [hideTooltipTimer, setHideTooltipTimer] = React.useState<any>();
    const [showTooltipTimer, setShowTooltipTimer] = React.useState<any>();
    
    const [tooltipWillShow, setTooltipWillShow] = React.useState<boolean>(false);
    const [tooltipWillDisappear, setTooltipWillDisappear] = React.useState<boolean>(false);
    const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);
    
    const navRef = React.useRef<HTMLDivElement>(null);

    const {
        user
    } = useAppSelector(state => state.session);

    React.useEffect(() => {
        const navBarRect = navRef.current.getBoundingClientRect();

        if (!navBarRect) {
            setUsedHighlightPosition(currentHighlightPosition);
            return;
        }

        const scrollOffsetTop = currentHighlightPosition.top - navBarRect.top + navRef.current.scrollTop;
        const hasToScroll = navRef.current.scrollHeight > (navBarRect.width + 1);

        setUsedHighlightPosition({
            left: expanded ? currentHighlightPosition.left : 0,
            top: scrollOffsetTop, 
            width: expanded ? currentHighlightPosition.width : 5, 
            height: currentHighlightPosition.height
        });

        if (hasToScroll) navRef.current.scrollTo({top: scrollOffsetTop, behavior: "smooth"});
    }, [expanded, currentHighlightPosition]);

    const showTooltip = (t: ISideNavTooltip | null) => {
        if (!t) {
            setTooltipWillShow(false);
            setTooltipWillDisappear(true);
            clearTimeout(showTooltipTimer);
            const x = setTimeout(() => setTooltipVisible(false), 400);
            setHideTooltipTimer(x);
            return;
        }

        setTooltip(t);

        setTooltipWillDisappear(false);
        clearTimeout(hideTooltipTimer);

        if (tooltipVisible) {
            setTooltipWillShow(false);
            setTooltipVisible(true);
            return;
        }

        setTooltipWillShow(true);
        const y = setTimeout(() => setTooltipVisible(true), 400);
        setShowTooltipTimer(y);
    }

    const sideNavClass = generateClassName("position-fixed top-0 start-0 d-flex flex-column align-items-start pt-3 pb-3 h-100 sidenav", {
        value: expanded,
        base: "sidenav-",
        standard: "collapsed",
        onTrue: "expanded ps-3"
    });

    const getSidebarLink = (path: IAppPath, display: boolean = true) => <SideNavLink path={path} visible={display} toggleTooltip={showTooltip} closeSidebar={() => setExpanded(false)} sideBarIsExpanded={expanded} setHighlightPosition={setCurrentHighlightPosition} />

    const sideNavHighlightStyle = generateStyle({
        name: "height",
        value: usedHighlightPosition.height,
        unit: "px"
    }, {
        name: "width",
        value: usedHighlightPosition.width,
        unit: "px"
    }, {
        name: "transform",
        value: expanded ? `translateX(${usedHighlightPosition.left}px)` : 'translateX(100%)',
        unit: "px"
    }, {
        name: "top",
        value: usedHighlightPosition.top,
        unit: "px"
    }, {
        name: "backgroundColor",
        value: "primaryAccent",
    });

    const sideNavHighlightClass = generateClassName("side-nav-highlight position-absolute", {
        value: expanded,
        onTrue: "side-nav-highlight-expanded"
    })

    const sideNavTooltipStyle = generateStyle({
        name: "top",
        value: tooltip.position.top,
        unit: "px"
    });

    const sideNavTooltipClass = generateClassName("sidenav-tooltip ps-3 pe-3 p-2 position-absolute", {
        value: tooltipWillShow,
        onTrue: "sidenav-tooltip-will-show"
    }, {
        value: tooltipWillDisappear, 
        onTrue: "sidenav-tooltip-will-hide"
    })

    return (
        <div className={sideNavClass} ref={navRef} >
            <div className={sideNavHighlightClass} style={sideNavHighlightStyle}></div>
            {
                tooltipVisible && !expanded && (
                    <div className={sideNavTooltipClass} style={sideNavTooltipStyle}>
                        <strong className="text-nowrap">{tooltip.title}</strong>
                        <br />
                        <span className="text-nowrap text-truncate">{tooltip.tooltip}</span>
                    </div>
                )
            }
            <div className="d-flex flex-row align-items-center justify-content-center pb-5" style={{width: "var(--sideNavWidth)"}}>
                <Icon onClick={() => setExpanded(!expanded)} icon={expanded ? "x" : "list"} size={SideNavIconSize} />
            </div>
            <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-between">
                <div className="d-flex flex-column gap-2 w-100">
                    {getSidebarLink(AppRoutes.Downloads)}
                    {getSidebarLink(AppRoutes.Tools)}
                    {getSidebarLink(AppRoutes.UsefulLinks)}
                    {getSidebarLink(AppRoutes.Training)}
                </div>
                <div className="d-flex flex-column gap-2 w-100">
                    {getSidebarLink(AppRoutes.CMS, !!user && !!user.email && user.email === "craig@vividinvests.com" || user.email === "j.caspary@jumoca.de")}
                    {getSidebarLink(AppRoutes.Favorites)}
                    {getSidebarLink(AppRoutes.Profile)}
                    {getSidebarLink(AppRoutes.About)}
                </div>
            </div>
            
        </div>
    )
}