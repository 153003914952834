import React from "react";
import LogInForm from "../components/session/LogInForm";
import Page from "../components/container/Page";
import AddinValueSlideshow from "../components/valueproposition/AddinValueSlideshow";
import Button from "../components/buttons/Button";
import LabelButton from "../components/buttons/LabelButton";
import { Asset, getAsset } from "../../util/util";
import { setCookie } from "../../util/cookies";

export default function LogInPage() {
    const [logInVisible, setLogInVisible] = React.useState<boolean>(false);

    return (
        <Page>
            {
                logInVisible 
                ? <LogInForm />
                : (
                    <div className="d-flex flex-column h-100 w-100 gap-5 justify-content-between align-items-center p-3">
                        <AddinValueSlideshow setLogInVisible={setLogInVisible} />
                        <div className="d-flex flex-column align-items-center w-100 gap-2 pb-3">
                            <div className="position-relative">
                                <img className="position-absolute" style={{left: "-10%", top: "-100%", zIndex: "1", transform: "rotateX(180deg)"}} src={getAsset(Asset.Arrow)} />
                                <Button onClick={async () => setLogInVisible(true)} text="Log in to MFA" icon=" " />
                            </div>
                            <LabelButton onClick={async () => window.open("https://mfaunderwriting.com/my-account/", "_blank")} text="Don't have an account yet? Sign up here!" />
                        </div>
                    </div>
                )
            }
        </Page>
    )
}