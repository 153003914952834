import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../../routes/AppRouter";
import CmsOverview from "../components/cms/CmsOverview";
import useWindowSize from "../../hooks/useWindowSize";
import CmsTableOverview from "../components/cms/CmsTableOverview";

export default function CmsPage() {
    const {
        width
    } = useWindowSize();

    return (
        <Page title={AppRoutes.CMS.Label} >
            {
                width > 1000 
                ? <CmsTableOverview />
                : <CmsOverview />
            }
        </Page>
    )
}