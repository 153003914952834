export const formatTime = (d: any): string => {
    if (!d) return "";

    try {
        return new Date(d).toLocaleTimeString("DE-de", {
            timeStyle: "short"
        });
    }
    catch {return ""}
}

const appendZero = (str: any): string => {
    if (!str) return "00";
    return ('0' + str).slice(-2);
}

export const formatDate = (d: any, withTime: boolean = false, withShortTime: boolean = false): string => {
    if (!d) return "";

    try {
        const date = new Date(d);

        if (isNaN(date.getDate())) return "";
        if (!date) return "";

        let result = `${appendZero(date.getDate())}.${appendZero(date.getMonth() + 1)}.${appendZero(date.getFullYear())}`;

        if (!withTime) return result;

        result += ` ${appendZero(date.getHours())}:${appendZero(date.getMinutes())}`;

        if (withShortTime) return result;

        result += `:${appendZero(date.getSeconds())}.${('00' + date.getMilliseconds()).slice(-3)}h`;

        return result;
    }
    catch {
        return ""
    }
}

export const formatNumber = (n: any): string => {
    try {
        let input = n;
        if (typeof n !== "number") input = parseFloat(n);
        return new Intl.NumberFormat("de-DE", {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(input);
    }
    catch {

    }

    return n;
}

export const formatCurrency = (n: any, currency: string = "EUR"): string => {
    try {
        if (typeof n !== "number") return n;
        return `${new Intl.NumberFormat("de-DE", { currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2}).format(n)} EUR`;
    }
    catch { }

    return n;
}