import React from "react";
import CmsLinkOverview from "./CmsLinkOverview";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { tutorialsUpdate, usefulLinksUpdate } from "../../../api/api";
import { getTutorials, getUsefulLinks } from "../../../state/slices/cmsSlice";
import CmsTableGroup from "./CmsTableGroup";

export enum CmsGroup {
    None,
    Tutorials,
    UsefulLinks
}

export default function CmsTableOverview() {
    const [expandedGroup, setExpandedGroup] = React.useState<CmsGroup>(CmsGroup.None);

    const {
        tutorials,
        usefulLinks
    } = useAppSelector(state => state.cms);

    return (
        <div className="d-flex flex-column gap-2 overflow-hidden pb-2">
            <CmsTableGroup 
                content={usefulLinks} 
                fetchData={getUsefulLinks}
                data={{expandedGroup: expandedGroup, group: CmsGroup.UsefulLinks, setExpandedGroup: setExpandedGroup, title: "Helpful websites" }} 
                updateFunction={usefulLinksUpdate}
            /> 
            <CmsTableGroup
                content={tutorials} 
                fetchData={getTutorials}
                data={{expandedGroup: expandedGroup, group: CmsGroup.Tutorials, setExpandedGroup: setExpandedGroup, title: "Tutorials" }} 
                updateFunction={tutorialsUpdate}
            />
        </div>
    )
}