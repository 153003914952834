import { ErrorMessage, Field, FieldAttributes } from "formik";
import React from "react";
import "./CmsTableCheckBox.css";
import IElementProps from "../../../types/ElementProps";
import { generateClassName } from "../../../hooks/useAttributes";

interface ICheckBoxProps extends IElementProps {
    value: boolean,
    setValue: (val: boolean) => void,
    readOnly?: boolean,
    bold?: boolean,
    type?: string
}

export default function CmsTableCheckBox({setValue, value}: ICheckBoxProps ) {
    
    return (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center form-check-input-container" style={{paddingRight: "6px", paddingLeft: "6px", maxWidth: "30px"}} onClick={() => setValue(!value)}>
            <input type="checkbox" className="form-check-input m-0 p-0" checked={value} readOnly/>
        </div>
    )
}