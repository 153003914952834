import React from "react";
import Card from "../cards/Card";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { Asset, getAsset } from "../../../util/util";
import Button from "../buttons/Button";
import LabelButton from "../buttons/LabelButton";
import useWindowSize from "../../../hooks/useWindowSize";
import "./AddinValueSlideshow.css";

interface IAddinValueSlideProps {
    title: string,
    text?: string,
    asset: Asset
}

export default function AddinValueSlideshow({setLogInVisible}: {setLogInVisible: (val: boolean) => void}) {

    const [asset, setAsset] = React.useState<number>(0);

    const assets: Array<IAddinValueSlideProps> = [
        {
            title: "Welcome to MFA!",
            text: "We're thrilled that you've downloaded our powerful underwriting add-in designed specifically for multifamily investors and analysts.",
            asset: Asset.ValueStart
        },
        {
            title: "Analyze today!",
            text: "With the MFA add-in, you'll have instant access to the most up-to-date underwriting files that are available for download from inside the add-in.",
            asset: Asset.ValueAnalysis
        },
        {
            title: "Powerful Tools!",
            text: "Create a free account to get access to our free underwriting tools including a robust underwriting tool, a market analysis, and a comprehensive underwriting checklist.",
            asset: Asset.ValueTools
        },
        {
            title: "Useful Links!",
            text: "Gain detailed insights into the market with our hand-picked collection of helpful third party links directly at your fingertips.",
            asset: Asset.ValueLinks
        },
        {
            title: "Save time!",
            text: "We've spent thousands of hours developing our models and services. Benefit from our experience and save time and money.",
            asset: Asset.ValueTime
        },
        {
            title: "A Smart Choice!",
            text: "Our add-in is an invaluable tool for multifamily investors looking to streamline their underwriting process and make informed investment decisions.",
            asset: Asset.ValueValuation
        },
        {
            title: "Sign up today!",
            text: "Create your free account on our website today. To do this, just click the button below to join. You will get instant access to all the latest features.",
            asset: Asset.ValueEnd
        }
    ]

    const assetCount = assets.length;

    if (asset < 0 || asset >= assetCount) return null;

    const usedAsset = assets[asset];

    if (!usedAsset) return null;
    
    return (
        <Card className="w-100 h-100 overflow-hidden d-flex flex-column align-items-center text-center" >
            <div className="d-flex addin-value-slide flex-column flex-sm-row w-100 h-100 justify-content-sm-between align-items-sm-center gap-2 overflow-hidden" style={{userSelect: "none"}} >
                <div className="d-flex flex-column w-100 align-items-center">
                    {
                        usedAsset.title && <h2 className="fw-bold text-uppercase" style={{color: "var(--primaryAccent)"}}>{usedAsset.title}</h2>
                    }
                    {
                        usedAsset.text && <p>{usedAsset.text}</p>
                    }
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center w-100 h-100 addin-value-slide-img-container" style={{userSelect: "none"}}>
                    <img src={getAsset(usedAsset.asset)} style={{width: "100%", height: "100%", objectFit: "contain", userSelect: "none"}}/>
                </div>
            </div>
            <div className="w-100 d-flex flex-row align-items-center justify-content-between" style={{minHeight: "50px"}}>
                {
                    asset !== 0 
                    ? <Button text="Back" color="#D0D0D0" icon="arrow-left" size={asset < (assetCount - 1) ? "small" : "regular" } onClick={async () => setAsset(asset - 1)} />
                    : <span></span>
                }
                {
                    <Button icon="arrow-right" iconPosition="end" text="Next Win" size={asset === (assetCount - 1) ? "small" : "regular" } onClick={async () => setAsset(asset + 1)} disabled={asset >= (assetCount - 1)} />
                }
            </div>
        </Card>
    )
}