import React from "react";
import {StatusCodes} from "http-status-codes";
import Page from "../components/container/Page";

interface IErrorProps {
    message?: string,
    status?: number,
    hideBackButton?: boolean
}

export default function ErrorPage({message = "An error occured. Please let us know about this so we can help prevent errors in the future.", status = StatusCodes.NOT_FOUND}: IErrorProps) {
    
    return (
        <Page>
            <div className="d-flex flex-column text-center align-items-center w-100">
                <div className="w-50">
                    <h1 className="fw-bold display-1" style={{color: "var(--primaryAccent)"}}>{status}</h1>
                    <p>{message}</p>
                </div>
            </div>
        </Page>
    )
}