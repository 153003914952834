import React, { ChangeEvent } from "react";
import PageTitle from "../container/PageTitle";
import Icon from "../icons/Icon";
import "./CmsLinkList.css";
import CmsLinkCategory from "./CmsLinkCategory";
import { generateClassName } from "../../../hooks/useAttributes";
import { AppRoutes } from "../../../routes/AppRouter";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setLogoVisible } from "../../../state/slices/uiSlice";
import { Status } from "../../../types/ReduxTypes";
import PageLoader from "../loader/PageLoader";
import { ICmsLinkCategory, ICmsLinkEntry } from "../../../types/WooTypes";
import { getUsefulLinks } from "../../../state/slices/cmsSlice";
import useCmsLinks from "../../../hooks/useCmsLinks";
import { ICmsLinkSlice } from "../../../state/slices/cmsSlice";
import { AsyncThunkAction } from "@reduxjs/toolkit";

export interface ICmsLinkListProps {
    singleItemsTitle: string,
    multipleItemsTitle: string,
    slice: ICmsLinkSlice,
    fetchData: () => AsyncThunkAction<ICmsLinkCategory[], undefined, any>,
    renderItem: (link: ICmsLinkEntry, index: number) => any
}

export default function CmsLinkList({slice, fetchData, renderItem, multipleItemsTitle, singleItemsTitle}: ICmsLinkListProps) {
    const [filter, setFilter] = React.useState<string>("");
    const [filterValue, setFilterValue] = React.useState("");
    const [activeCategory, setActiveCategory] = React.useState<ICmsLinkCategory>();
    const [matchingLinks, setMatchingLinks] = React.useState<Array<ICmsLinkEntry>>();

    const dispatch = useAppDispatch();

    const {
        data,
        status
    } = slice;

    const {
        filterLinks,
        sortLinks
    } = useCmsLinks(filter, data, activeCategory);


    React.useEffect(() => {
        if (status === Status.Idle || status === Status.Error) dispatch(fetchData());
    }, []);

    React.useEffect(() => {
        const matches = filterLinks();
        setMatchingLinks(matches);
    }, [filter, data]);
    
    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e || !e.target || !e.target.value) {
            updateFilter("");
            return;
        }

        updateFilter(e.target.value);
    }

    const updateFilter = (s: string) => {
        setFilter(s.toUpperCase().trim());
        setFilterValue(s);
    }

    const changeActiveCategory = (c: ICmsLinkCategory | undefined) => {
        setActiveCategory(c);

        if (!c) dispatch(setLogoVisible(true));
        else dispatch(setLogoVisible(false));
    }
    
    return (
        <div className="d-flex flex-column gap-2 h-100 w-100">
            <div className="d-flex flex-row align-items-center gap-2">
                {
                    activeCategory && <Icon icon="arrow-left" size={18} onClick={() => changeActiveCategory(undefined)} />
                }
                <PageTitle title={activeCategory ? activeCategory.title : multipleItemsTitle} />
            </div>
            {
                status === Status.Loading
                ? <PageLoader />
                : (
                    <>
                        <div className="position-relative">
                            <input className="form-control useful-links-search-bar w-100" type="text" value={filterValue} onChange={handleFilterChange} placeholder={activeCategory ? `Search in category...` : "Search..."} />
                            <Icon className="position-absolute end-0 top-50 translate-middle" icon={filter ? "x" : "search"} onClick={filter ? () => updateFilter("") : undefined} />
                        </div>
                        {
                            !!filter && !!matchingLinks && !!matchingLinks.length && <span>{matchingLinks.length === 1 ? "1 Result" : `${matchingLinks.length} Results`}</span>
                        }
                        <div className="d-flex flex-column h-100 gap-2" style={{overflowY: "auto", overflowX: "hidden"}}>
                            {
                                filter && !!filter.length
                                ? (
                                    matchingLinks && !!matchingLinks.length
                                    ? sortLinks(matchingLinks).map(renderItem)
                                    : <span>No results for your search.</span>
                                )
                                : (
                                    activeCategory
                                    ? (
                                        activeCategory.items && !!activeCategory.items.length 
                                        ? sortLinks(activeCategory.items).map(renderItem)
                                        : <span>No items in this category.</span>
                                    )
                                    : (
                                        data && !!data.length
                                        ? data.map((u, index) => (
                                            <CmsLinkCategory 
                                                key={`${u.title}-${index}`} 
                                                category={u} 
                                                setActiveCategory={changeActiveCategory} 
                                                multipleItemsTitle={multipleItemsTitle} 
                                                singleItemTitle={singleItemsTitle}
                                            />
                                        ))
                                        : <span>No categories to display.</span>
                                    )
                                )
                            }
                        </div>
                    </>
                )
            }
            
        </div>
    )
}