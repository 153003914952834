export enum Status {
    Idle,
    Loading,
    Error,
    Success
}

export interface IStatefulSlice {
    status: Status,
    error?: any
}