import React, { useEffect, useState } from "react";
import { getColorFromType, getIconFromType } from "../../../config/ModalTypes";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useModal from "../../../hooks/useModal";
import Icon from "../icons/Icon";
import "./ModalBanner.css";
import { IElementPosition } from "../nav/SideNav";
import useWindowSize from "../../../hooks/useWindowSize";

export default function ModalBanner() {

    const [showTimer, setShowTimer] = useState<any>();
    const [dimensions, setDimensions] = React.useState<IElementPosition>();

    const contentRef = React.useRef<HTMLParagraphElement>(null);
    const windowSize = useWindowSize();

    const {
        visible,
        text,
        id,
        type
    } = useAppSelector(state => state.modal);

    const showModal = useModal();

    useEffect(() => {
        if (!visible) return;
    
        clearTimeout(showTimer);
        const timer = setTimeout(() => showModal(false), 4000);
        setShowTimer(timer);

    }, [visible, id]);
    
    useEffect(() => {
        if (!contentRef || !contentRef.current) return;

        const bounds = contentRef.current.getBoundingClientRect();

        if (!bounds) return;

        setDimensions(bounds);

    }, [contentRef, windowSize, visible, id, text]);

    if (!visible) return null;

    const style = generateStyle({
        name: "backgroundColor",
        value: getColorFromType(type)
    }, {
        name: "color",
        value: "#FFFFFF"
    }, {
        name: "minHeight",
        value: dimensions ? dimensions.height + 24 : 48,
        unit: "px"
    });

    const className = generateClassName("modal-banner d-flex align-items-center justify-content-between", {
        value: visible,
        onTrue: "modal-banner-visible",
        standard: "modal-banner-hidden"
    })

    const realText = text && typeof text === "string" && text.length > 0 ? text : "An error occured.";

    return (
        <div className={className} style={style}>
            <div className="d-flex flex-row align-items-center modal-banner-content h-100" >
                <Icon icon={getIconFromType(type)} />
                <div className="ms-3 fw-bold h-100" >
                    <p className="m-0 p-0" ref={contentRef}>{realText}</p>
                </div>
            </div>
            <Icon icon="x" onClick={() => showModal(false)}/>
        </div>
    )
}