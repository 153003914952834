import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import KnowledgeVaultLink from "../knowledge/UsefulLink";
import { Status } from "../../../types/ReduxTypes";
import { getStoredFavorites } from "../../../state/slices/favoritesSlice";
import PageLoader from "../loader/PageLoader";

export default function FavoritesList() {
    const {
        favorites,
        status
    } = useAppSelector(state => state.favorites);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (status === Status.Idle || status === Status.Error) dispatch(getStoredFavorites());
    }, []);

    if (status === Status.Loading) return <PageLoader />
    
    if (!favorites || !favorites.length) return <span>No favorites yet. Save a link from our collection to get started.</span>

    return (
        <div className="d-flex flex-column gap-2 h-100" style={{overflowY: "auto"}}>
            {
                favorites.map((f, index) => <KnowledgeVaultLink key={`${f.url}-${index}`} link={f} />)
            }
        </div>
    )
}