import { ErrorMessage, Field, FieldAttributes } from "formik";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import IElementProps from "../../../types/ElementProps";
import "./FieldWithLabel.css";

interface IFieldWithLabelProps extends IElementProps {
    label: string,
    placeholder?: string,
    inputClass?: string,
    name: string,
    as?: string,
    readOnly?: boolean,
    bold?: boolean,
    type?: string,
    inline?: boolean
}

export default function FieldWithLabel({label, inline, readOnly, as, type, placeholder, className, inputClass, name, bold, children}: IFieldWithLabelProps) {
    
    const fieldId = `${name}field`;

    const labelClass = generateClassName("form-label m-0", {value: bold, onTrue: "fw-bold"});
    const controlClass = generateClassName(inputClass, "form-control text-field w-100");
    const containerClass = generateClassName(className, "d-flex",
    {
        value: inline,
        standard: "flex-column align-items-start gap-1",
        onTrue: "flex-row align-items-center gap-3"
    });

    return (
        <div className={containerClass}>
            <label htmlFor={fieldId} className={labelClass}>{label}</label>
            <div className="position-relative w-100">
                <Field as={as} disabled={readOnly} id={fieldId} name={name} type={type} placeholder={placeholder || label} className={controlClass} />
                { children }
            </div>
            <ErrorMessage name={name} render={(msg) => <span className="mt-0" style={{color: "#FF0000"}}>{msg}</span>} />
        </div>
    )
}