import React from "react";
import * as purify from "dompurify";
import { IElementPosition } from "../nav/SideNav";
import useWindowSize from "../../../hooks/useWindowSize";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { LabelBase } from "@fluentui/react";
import LabelButton from "../buttons/LabelButton";
import "./ExpandableTextBox.css";

export default function ExpandableTextBox({value, defaultValue}: {value?: string | undefined | null, defaultValue?: string}) {
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [height, setHeight] = React.useState<number>(0);

    const size = useWindowSize();

    const contentRef = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (!contentRef || !contentRef.current) return;

        const contentSize = contentRef.current.scrollHeight;

        setHeight(contentSize);

    }, [contentRef, size]);

    const realValue = value || defaultValue;

    if (!realValue) return null;

    const cleanValue = purify.sanitize(realValue);

    const maxHeight = 42;

    const style = generateStyle({
        name: "maxHeight",
        value: isExpanded ? height : maxHeight,
        unit: "px"
    });

    const contentClass = generateClassName("overflow-hidden expandable-text-box-content", {
        value: isExpanded,
        standard: "expandable-text-box-content-collapsed"
    });

    return (
        <div className="d-flex flex-column w-100">
            <div dangerouslySetInnerHTML={{__html: cleanValue}} className={contentClass} style={style} ref={contentRef} />
            { 
                height > maxHeight && <LabelButton icon={isExpanded ? "chevron-up" : "chevron-down"} onClick={async () => setIsExpanded(!isExpanded)} text={isExpanded ? "View less" : "View more"} />
            }
        </div>
    )
}