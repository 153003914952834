import React from "react";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import Icon from "../icons/Icon";
import { ICmsLinkCategory } from "../../../types/WooTypes";

export interface ICmsLinkCategoryProps {
    category: ICmsLinkCategory, 
    setActiveCategory: (c: ICmsLinkCategory) => void,
    singleItemTitle: string,
    multipleItemsTitle: string
}

export default function CmsLinkCategory({category, setActiveCategory, multipleItemsTitle, singleItemTitle}: ICmsLinkCategoryProps) {
    if (!category || !category.items || !category.items.length) return null;

    const linkCount = category.items.length;
    const isSingular = linkCount === 1;

    return (
        <Card onClick={() => setActiveCategory(category)}>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-column">
                    <h6>{category.title}</h6>
                    <span>{linkCount} {isSingular ?  singleItemTitle : multipleItemsTitle}</span>
                </div>
                <Icon icon="arrow-right" size={16} />
            </div>
        </Card>
    )
}