import { ICmsLinkCategory, ICmsLinkEntry } from "../types/WooTypes";
import { useAppSelector } from "./reduxHooks";

export default function useCmsLinks(filter, data: Array<ICmsLinkCategory>, activeCategory?: ICmsLinkCategory) {
    const sortLinks = (links: Array<ICmsLinkEntry>): Array<ICmsLinkEntry> => {
        if (!links || !links.length) return [];
        const sortableLinks = [...links];
        return sortableLinks.sort((a: ICmsLinkEntry, b: ICmsLinkEntry) => a.title.localeCompare(b.title, undefined, {numeric: true, sensitivity: "base"}));
    }

    const filterLinks = (): Array<ICmsLinkEntry> => {
        if (!filter) return [];
        
        const isLinkMatching = (l: ICmsLinkEntry): boolean => {
            try {
                if (!l) return false;
                if (l.title && l.title.toUpperCase().includes(filter)) return true;
                if (l.tags && l.tags.toUpperCase().includes(filter)) return true;
                return l.description && l.description.toUpperCase().includes(filter);
            }
            catch { return false; }
        }

        if (!activeCategory) {
            let results: Array<ICmsLinkEntry> = [];
            for (const category of data) {
                if (!category || !category.items || !category.items.length) continue;
                const matchingLinks = category.items.filter(a => isLinkMatching(a));
                if (!matchingLinks || !matchingLinks.length) continue;
                results = results.concat(matchingLinks);
            }
            return results;
        }
        
        if (!activeCategory.items || !activeCategory.items.length) return [];
        return activeCategory.items.filter(a => isLinkMatching(a));
    }

    return {
        filterLinks,
        sortLinks
    }
}