import React from "react";
import Button, { IButtonProps } from "./Button";
import Icon from "../icons/Icon";
import { generateClassName } from "../../../hooks/useAttributes";
import LabelButton from "./LabelButton";

export interface ITwoStageButtonProps extends IButtonProps {
    firstStageText: string,
    firstStageIcon?: string,
    firstStageIconSize?: number
    containerClass?: string,
    useLabelButton?: boolean,
}

export default function TwoStageButton(props: ITwoStageButtonProps) {
    const [isClicked, setIsClicked] = React.useState<boolean>(false);

    const containerClass = generateClassName(props.containerClass, "d-flex flex-row gap-1 align-items-center");

    return (
        <div className={containerClass}>
            {
                isClicked 
                ? (
                    props.useLabelButton ? <LabelButton {...props} /> : <Button {...props} />
                )
                : (
                    props.useLabelButton
                    ? <LabelButton text={props.firstStageText} size={props.size} color={props.color} className={props.className} icon={props.firstStageIcon} onClick={async () => setIsClicked(true)} />
                    : <Button text={props.firstStageText} size={props.size} color={props.color} className={props.className} icon={props.firstStageIcon} onClick={async () => setIsClicked(true)} />
                )
            }
            {
                isClicked && <Icon icon="x" size={props.firstStageIconSize || 24} onClick={() => setIsClicked(false)} />
            }
        </div>
    )
}