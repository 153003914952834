import React from "react";
import Button from "../buttons/Button";
import useRoutes from "../../../hooks/useRoutes";

export default function ShowTutorialButton({link, index}: {link: string, index: number}) {
    const {
        openInBrowser     
    } = useRoutes();

    const openTutorial = async () => openInBrowser(link);

    return <Button className="align-self-start w-100" disabled={!link} text={`Tutorial ${index + 1}`} icon="play" size="small" color="#F0F0F0" onClick={openTutorial} />;
}