import React from "react";
import Button from "../buttons/Button";
import useRoutes from "../../../hooks/useRoutes";
import LabelButton from "../buttons/LabelButton";
import { IDownloadableProduct } from "../../../types/WooTypes";

export default function GoToStoreButton({product}: {product: IDownloadableProduct}) {
    const {
        openInBrowser
    } = useRoutes();

    if (!product.url) return null;

    return <Button text="View in Store" icon="box-arrow-up-right" size="small" onClick={async () => openInBrowser(product.url)} />
}