export enum WooProductCategory {
    Models = "MFA Model",
    Tools = "MFA Tool"
}

export interface IWooDate {
    date: string,
    timezone_type: number,
    timezone: string
}

export interface IWooUser {
    name: string,
    email: string,
    url: string
}

export interface IWooProduct {
    name: string,
    price: number,
    description: string,
    short_description: string
}

export interface IWooDownload {
    id: string,
    name: string,
    file: string
}

export interface IDownloadableProduct {
    id: string,
    product: IWooProduct,
    tutorials: Array<string>,
    requires_array_formula: boolean,
    can_download: boolean,
    url: string,
    downloads: Array<IWooDownload>
}

export interface ICmsLinkCategory {
    title: string,
    items: Array<ICmsLinkEntry>
}

export interface ICmsLinkEntry {
    title: string,
    description: string,
    tags: string,
    url: string
}
