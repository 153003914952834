import React from "react";
import IElementProps from "../../../types/ElementProps";
import "./Card.css";
import { generateClassName } from "../../../hooks/useAttributes";

interface ICardProps extends IElementProps {
    noPadding?: boolean,
    padding?: number | string
}

export default function Card({className, children, onClick, noPadding, padding = 4, style}: ICardProps) {
    const cardClass = generateClassName(className, "card-container position-relative", {
        value: !!onClick,
        onTrue: "card-clickable"
    }, {
        base: "p-",
        value: noPadding,
        onTrue: "0",
        standard: padding
    });

    return (
        <div className={cardClass} onClick={onClick ? () => onClick() : undefined} style={style || undefined}>
            {children}
        </div>
    )
}