import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatefulSlice, Status } from "../../types/ReduxTypes";
import { ICmsLinkCategory, ICmsLinkEntry } from "../../types/WooTypes";
import { tutorialsGetAll, usefulLinksGetAll } from "../../api/api";
import { RootState } from "../store";

const sortCmsLinkCategories = (data: Array<ICmsLinkCategory>): Array<ICmsLinkCategory> => data.sort((a: ICmsLinkCategory, b: ICmsLinkCategory) => a.title.localeCompare(b.title, undefined, {numeric: true, sensitivity: 'base'}));

export const getTutorials = createAsyncThunk<Array<ICmsLinkCategory>, undefined, {rejectValue: any}>("usefulLinks/getTutorials", async (arg, {signal, getState, rejectWithValue}) => {
    try {
        const {
            session
        } = getState() as RootState;

        const result = await tutorialsGetAll(signal, {uid: session.uniqueIdentifier, token: session.sessionToken});

        if (result && result.canceled) return null;
        if (!result || !result.success) return rejectWithValue(null);
        if (!result.data || !result.data.length) return result.data;

        return sortCmsLinkCategories(result.data);
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});


export const getUsefulLinks = createAsyncThunk<Array<ICmsLinkCategory>, undefined, {rejectValue: any}>("usefulLinks/getUsefulLinks", async (arg, {signal, getState, rejectWithValue}) => {
    try {
        const {
            session
        } = getState() as RootState;

        const result = await usefulLinksGetAll(signal, {uid: session.uniqueIdentifier, token: session.sessionToken});

        if (result && result.canceled) return null;
        if (!result || !result.success) return rejectWithValue(null);

        if (!result.data || !result.data.length) return result.data;

        return sortCmsLinkCategories(result.data);
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

export interface ICmsLinkSlice extends IStatefulSlice {
    data: Array<ICmsLinkCategory>
}

export interface ICmsSlice {
    tutorials: ICmsLinkSlice,
    usefulLinks: ICmsLinkSlice
}

export const CmsSlice = createSlice({
    name: "cms",
    initialState: {
        tutorials: {
            status: Status.Idle,
            data: []
        },
        usefulLinks: {
            status: Status.Idle,
            data: []
        }
    } as ICmsSlice,
    reducers: { },
    extraReducers: (builder) => {
        builder
        .addCase(getTutorials.pending, (state, action) => {
            state.tutorials.status = Status.Loading;
        })
        .addCase(getTutorials.fulfilled, (state, action) => {
            state.tutorials.status = Status.Success;
            if (!action.payload || !action.payload) return;
            state.tutorials.data = action.payload;
        })
        .addCase(getTutorials.rejected, (state, action) => {
            state.tutorials.status = Status.Error;
            state.tutorials.error = action.payload || action.error;
        })
        .addCase(getUsefulLinks.pending, (state, action) => {
            state.usefulLinks.status = Status.Loading;
        })
        .addCase(getUsefulLinks.fulfilled, (state, action) => {
            state.usefulLinks.status = Status.Success;
            if (!action.payload || !action.payload) return;
            state.usefulLinks.data = action.payload;
        })
        .addCase(getUsefulLinks.rejected, (state, action) => {
            state.usefulLinks.status = Status.Error;
            state.usefulLinks.error = action.payload || action.error;
        });
    }
});

export default CmsSlice.reducer;