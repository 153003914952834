import React from "react";
import { Formik, Form, Field } from "formik";
import Button from "../buttons/Button";
import FieldWithLabel from "../formik/FieldWithLabel";
import * as yup from "yup";
import useApi from "../../../hooks/useApi";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setSession } from "../../../state/slices/sessionSlice";
import { usersLogIn } from "../../../api/api";
import { IUserLogInRequest } from "../../../types/ApiRequests";
import Card from "../cards/Card";
import { LabelBase } from "@fluentui/react";
import LabelButton from "../buttons/LabelButton";
import { Cookies, getCookie } from "../../../util/cookies";
import useModal from "../../../hooks/useModal";
import { ModalType } from "../../../config/ModalTypes";

export default function LogInForm() {

    const callApi = useApi();
    const dispatch = useAppDispatch();
    const showModal = useModal();

    return (
        <Formik
            initialValues={{
                username: "",
                password: ""
            } as IUserLogInRequest}
            validationSchema={yup.object().shape({
                username: yup.string().required("Please enter a username."),
                password: yup.string().required("Please enter a password.")
            })}

            onSubmit={async (values) => {
                if (!values) return;

                const res = await callApi(usersLogIn(values));

                if (!res || !res.success) {
                    dispatch(setSession(null));
                    
                    showModal({
                        text: "Couldn't log you in. Please recheck your username and password.",
                        type: ModalType.Error
                    });

                    return;
                }
                
                dispatch(setSession(res.data));

                const session = getCookie(Cookies.Session);

                if (!session) {
                    showModal({
                        text: "There was an error logging you in. Please try again.",
                        type: ModalType.Error
                    });

                    return;
                }
            }}
        >
            {
                (formik) => (
                    <Form className="d-flex flex-column gap-3 w-100 p-3">
                        <div className="d-flex flex-column">
                            <h5 className="fw-bold m-0 p-0">Log in</h5>
                            <span>Please log in with your MFA store credentials.</span>
                        </div>
                        <Card>
                            <div className="d-flex flex-column gap-3 w-100">
                                <FieldWithLabel bold name="username" label="Your Username" placeholder="Please enter your username or email address" />
                                <FieldWithLabel bold name="password" type="password" label="Your Password" placeholder="Please enter your password" />
                                <Button type="submit" icon="box-arrow-in-right" text="Log in" imperativeLoading={formik.isSubmitting} loadingText="Logging you in..." />
                            </div>
                        </Card>
                        <LabelButton text="No account? Create one here!" onClick={async () => window.open("https://mfaunderwriting.com/my-account/", "_blank")} />
                    </Form>
                )
            }
        </Formik>
    )
}