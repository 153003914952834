import React from "react";
import Button from "../buttons/Button";
import { Cookies, getCookie } from "../../../util/cookies";
import useDownloads from "../../../hooks/useDownloads";
import { productsDownloadSingle } from "../../../api/api";
import { IDownloadableProduct, IWooDownload, IWooProduct } from "../../../types/WooTypes";

export default function DownloadProductButton({product, download}: {product: IDownloadableProduct, download: IWooDownload}) {
    const downloadFile = useDownloads();

    const getExcelFileExtensionFromDownloadUrl = () => {
        try {
            const filePath = download.file;
    
            if (!filePath) return "";

            const ext = filePath.split(".").pop();

            if (!ext) return "";

            if (/\d/.test(ext)) return "";
            if (!ext.startsWith("xl")) return "";

            if (ext.length < 3 || ext.length > 4) return "";

            return ext;
        }
        catch {}

        return "";
    }

    const downloadAndOpen = async () => await downloadFile(
        download.name, 
        product.requires_array_formula, 
        productsDownloadSingle({file: download.file, download: download.id, product: product.id, token: getCookie(Cookies.Session), uid: getCookie(Cookies.UniqueId)}),
        getExcelFileExtensionFromDownloadUrl()
    );

    return <Button size="small" text="Download File" loadingText="Downloading..." icon="download" onClick={downloadAndOpen} />
}