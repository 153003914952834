import React from "react";
import { generateStyle } from "../../../hooks/useAttributes";
import "./Logo.css";
import { Asset, getAsset } from "../../../util/util";

export default function Logo({size}: {size?: number}) {
    const logoStyle = generateStyle({
        name: "width", 
        value: size,
        standard: 250,
        unit: "px"
    }, {
        name: "userSelect",
        value: "none"
    });

    return (
        <div style={logoStyle} className="d-flex flex-row align-items-center justify-content-center">
            <img className="logo-image w-100 h-100" src={getAsset(Asset.Logo)} />
        </div>
    )
}