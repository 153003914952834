import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../../routes/AppRouter";
import ProductsList from "../components/products/ProductsList";
import { WooProductCategory } from "../../types/WooTypes";

export default function DownloadsPage() {
    return (
        <Page title={AppRoutes.Downloads.Label}>
            <ProductsList category={WooProductCategory.Models} />
        </Page>
    )
}