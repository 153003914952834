import React, { MouseEventHandler } from "react";
import IElementProps from "../../../types/ElementProps";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { formatDate } from "../../../util/formatter";
import "./TableCell.css";

export enum TableDensity {
    Condensed = "condensed",
    Standard = "standard",
    Comfortable = "comfortable"
}

interface ITableCellProps extends IElementProps {
    isHeader?: boolean,
    content?: any,
    bold?: boolean,
    value?: boolean,
    date?: Date | string | number,
    labelOnTrue?: string,
    fillAvailableSpace?: boolean,
    density?: TableDensity,
    resizeable?: boolean,
    labelOnFalse?: string,
    align?:  "top" | "middle" | "bottom",
    justify?: "end" | "center" | "start",
    colSpan?: number,
    showTime?: boolean,
    rowSpan?: number
}

export default function TableCell({isHeader, align = "top", justify = "start", showTime = false, density = TableDensity.Standard, date, fillAvailableSpace, labelOnFalse, labelOnTrue, className, bold, colSpan, rowSpan, content, children}: ITableCellProps) {

    const [currentResizeStart, setCurrentResizeStart] = React.useState<number>(0);
    const [currentResizeWidth, setCurrentResizeWidth] = React.useState<number>(0);
    const [headerCellRef, setHeaderCellRef] = React.useState<HTMLTableCellElement>();

    const headerCell = React.createRef<HTMLTableCellElement>();

    React.useEffect(() => {
        if (!headerCell || !headerCell.current) return;
        setHeaderCellRef(headerCell.current);
        setCurrentResizeWidth(getCurrentColumnWidth());
    }, [headerCell]);

    const cellClass = generateClassName("table-cell", className, {
        base: "align-",
        value: align
    }, {
        base: "text-",
        value: justify
    }, {
        value: bold,
        onTrue: "fw-bold"
    }, 
    {
        value: fillAvailableSpace,
        onTrue: ""
    }, {
        value: isHeader,
        onTrue: "table-cell-header text-nowrap"
    });

    const contentWrapperClass = generateClassName("w-100 h-100", {
        base: "table-cell-density-",
        value: density,

    });

    const getCurrentColumnWidth = (): number => {
        try {
            return parseInt(window.getComputedStyle(headerCellRef).width, 10);
        }
        catch { }
        
        return 0;
    }
    
    const handleResizerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setCurrentResizeWidth(getCurrentColumnWidth());
        setCurrentResizeStart(e.clientX);

        document.addEventListener("mousemove", handleResize);
        document.addEventListener("mouseup", handleMouseUp);
    }
    
    const handleResize = (e: any) => {
        try {
            const leftMouseIsPressed = detectLeftButton(e);

            if (!leftMouseIsPressed) return handleMouseUp(e);
            if (!headerCellRef) return;
            if (currentResizeStart === 0 || currentResizeWidth === 0) return handleResizerClick(e);

            const xDelta = e.clientX - currentResizeStart;

            headerCellRef.style.width = `${currentResizeWidth + xDelta}px`;
        }
        catch {}
    }
    
    function detectLeftButton(evt: any) {
        evt = evt || window.event;
        if ("buttons" in evt) return evt.buttons == 1;
        var button = evt.which || evt.button;
        return button == 1;
    }

    const handleMouseUp = (e: any) => {
        document.removeEventListener("mousemove", handleResize);
        document.removeEventListener("mouseup", handleMouseUp);
    }

    const contentClass = generateClassName({
        value: isHeader,
        onTrue: "text-nowrap"
    });

    if (date !== undefined) children = <span>{formatDate(date, showTime)}</span>
    else if (isHeader && content) children = <span style={{fontSize: "1.0em"}} className="fw-bold">{content}</span>
    else if (!children && content) children = <span className={contentClass}>{content}</span>
    
    const element = isHeader ? "th" : "td";

    const cellContent = (
        <div className={contentWrapperClass}>
            {children}
        </div>
    );

    const cell = (isHeader) ? (
        <div className="d-flex flex-row h-100 w-100 align-items-center">
            {cellContent}        
            <div className="resizer" onMouseDown={handleResizerClick} />
        </div>
    ): cellContent;

    return React.createElement(element, {className: cellClass, colSpan: colSpan, rowSpan: rowSpan, ref: isHeader ? headerCell : undefined, style: {minWidth: 0}}, cell);
}