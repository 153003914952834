import React from "react";
import Card from "../cards/Card";
import Button from "../buttons/Button";
import useRoutes from "../../../hooks/useRoutes";
import ExpandableTextBox from "../text/ExpandableTextBox";
import { ICmsLinkEntry } from "../../../types/WooTypes";

export default function TutorialItem({tutorial}: {tutorial: ICmsLinkEntry}) {
    const {
        openInBrowser     
    } = useRoutes();

    if (!tutorial) return null;

    const openTutorial = async () => openInBrowser(tutorial.url);

    return (
        <Card className="d-flex flex-column gap-1">
            <h6 className="m-0 p-0">{tutorial.title}</h6>
            <ExpandableTextBox value={tutorial.description} />
            <Button icon="box-arrow-up-right" onClick={openTutorial} text="View tutorial" />
        </Card>
    )
}