import React from "react";
import "./TableRow.css";
import IElementProps from "../../../types/ElementProps";
import { generateClassName } from "../../../hooks/useAttributes";

interface ITableRowProps extends IElementProps {
}

export default function TableRow({children, className, onClick}: ITableRowProps) {

    const rowClass = generateClassName(className, "table-row", {
        value: onClick,
        onTrue: "table-row-clickable"
    });

    return (
        <tr className={rowClass} onClick={onClick ? onClick() : undefined}>
            {children}
        </tr>
    )
}