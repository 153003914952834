import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import Button from "../buttons/Button";
import { setSession, validateSession } from "../../../state/slices/sessionSlice";
import { Cookies, deleteCookie } from "../../../util/cookies";
import useRoutes from "../../../hooks/useRoutes";
import { AppRoute } from "../../../routes/AppRouter";
import useApi from "../../../hooks/useApi";
import { usersLogOut } from "../../../api/api";
import { clearFavorites } from "../../../state/slices/favoritesSlice";
import { clearProducts } from "../../../state/slices/productsSlice";

export default function LogOutButton() {

    const {
        sessionToken,
        uniqueIdentifier     
    } = useAppSelector(state => state.session);

    const {
        navigate
    } = useRoutes();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const clickHandler = async () => {
        try {
            await callApi(usersLogOut({token: sessionToken, uid: uniqueIdentifier}));
            
            dispatch(clearProducts(null));
            dispatch(clearFavorites(null));
            
            deleteCookie(Cookies.Session);
            deleteCookie(Cookies.UniqueId);

            dispatch(setSession(null));

            navigate(AppRoute.Downloads);

            await dispatch(validateSession());
        }
        catch (err) {
            console.log(err);
        }
    }

    return <Button color="#F05353" text="Log out" icon="power" onClick={clickHandler} />
}