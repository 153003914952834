import React from "react";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import IElementProps from "../../../types/ElementProps";

interface ILoadingSpinnerProps extends IElementProps {
    fullscreen?: boolean,
    centered?: boolean,
    size?: number,
    text?: string,
    color?: string
}

export default function LoadingSpinner({fullscreen, className, centered = true, size = 18, text, color}: ILoadingSpinnerProps) {

    const actualClass = generateClassName(className, "d-flex flex-row loading-spinner align-items-center", {value: fullscreen, onTrue: "h-100 w-100"}, 
    {
        base: "justify-content-",
        value: centered,
        onTrue: "center",
        standard: "start"
    }, {
        value: text,
        onTrue: "gap-2"
    });

    const style = generateStyle(
        {name: "width", value: size, standard: "24", unit: "px", important: true},
        {name: "height", value: size, standard: "24", unit: "px", important: true},
        {name: "borderWidth", value: size / 24 < 1 ? 1 : size / 24, unit: "px"},
        {name: "borderBottomColor", value: color, standard: "#000000"},
        {name: "borderTopColor", value: color, standard: "#000000"},
        {name: "borderLeftColor", value: color, standard: "#000000"},
    );

    return (
        <div className={actualClass}>
            <div style={style} className="spinner spinner-border"></div>
            {
                text && <span>{text}</span>
            }
        </div>
    )
}