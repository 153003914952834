import React from "react";
import "./Table.css";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import IElementProps from "../../../types/ElementProps";
import { generateClassName } from "../../../hooks/useAttributes";

interface ITableProps extends IElementProps {
    border?: boolean,
    headers: string[]
}

export default function Table({children, headers, className, border = true}: ITableProps) {

    const wrapperClass = generateClassName("table-wrapper", className, 
    {
        value: border,
        onTrue: "table-wrapper-border"
    });

    return (
        <div className={wrapperClass}>
            <table className="table w-100 table-responsive">
                <thead>
                    {
                        headers && !!headers.length && (
                            <tr>
                                {
                                    headers.map((h: string, index: number) => <TableCell isHeader content={h} key={h || `table-header-${index}`} />)
                                }
                            </tr>
                        )
                    }
                </thead>
                <tbody className="w-100 table-content">
                    {
                       children
                    }
                </tbody>
            </table>
        </div>
    )
}